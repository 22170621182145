import {DEFAULT_PLATFORM_PROJECT_TYPE_DICT} from '../../+project/index';
import {firestoreClassCollection} from './classes';
import {firestoreStudyGroupCollection} from './study-groups';

export * from './app';
export * from './chat';
export * from './classes';
export * from './courses';
export * from './investigations';
export * from './model';
export * from './notes';
export * from './projects';
export * from './quiz';
export * from './quiz-grades';
export * from './quiz-questions-by-project';
export * from './research';
export * from './study-groups';
export * from './subjects';
export * from './user';

export function firestoreCollectionByProjectType(projectType: number) {
  if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
    return firestoreClassCollection();
  }

  if (projectType === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
    return firestoreStudyGroupCollection();
  }

  return '';
}
