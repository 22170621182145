import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {firestoreProjectPathByParams, firestoreProjectSubPath} from '../firebase/database-paths';
import {CollaborativeProject, CreateProjectParams, CreateSubProjectParams, Project} from './project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private customFirestoreService: NgPatFirestoreService) {}

  createProject$(project: Project | CollaborativeProject, params: CreateProjectParams, uid: string) {
    const fireStorePath = firestoreProjectPathByParams(params, uid, project.id);
    return this.customFirestoreService.upsertDoc$(fireStorePath, project);
  }

  createSubProject$(
    project: Project | CollaborativeProject,
    baseParams: CreateSubProjectParams,
    projectParams: CreateSubProjectParams,
    uid: string
  ) {
    const firestorePath = `${firestoreProjectPathByParams(
      baseParams,
      uid,
      baseParams.parentProject.id
    )}/${firestoreProjectSubPath(projectParams, project.id)}`;
    return this.customFirestoreService.upsertDoc$(firestorePath, project);
  }
}
