import {ComponentStore} from '@ngrx/component-store';
import {
  Comparer,
  createEntityAdapter,
  Dictionary,
  EntityAdapter,
  EntityMap,
  EntityMapOne,
  EntityState,
  IdSelector,
  Update
} from '@ngrx/entity';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export interface DefaultSignalsParams {
  selectedId: string | number | null;
  isLoaded: boolean;
  isLoading: boolean;
  error: any | null;
}

export type SignalsEntityState<T> = EntityState<T> & DefaultSignalsParams;

export interface SignalEntityOptions<T> {
  selectId?: IdSelector<T>;
  sortComparer?: false | Comparer<T>;
}

function selectFirstIdIfNoIdSelected<T>(
  state: EntityState<T> & DefaultSignalsParams
): EntityState<T> & DefaultSignalsParams {
  if (state.selectedId !== null && state.selectedId !== undefined) {
    return state;
  }
  const firstId: string | number | undefined = state.ids[0];
  if (firstId !== undefined) {
    return {...state, selectedId: firstId};
  }
  return state;
}

function selectPreviousIdIfCurrentDeleted<T>(
  state: EntityState<T> & DefaultSignalsParams,
  deletedIds: string[] | number[]
): EntityState<T> & DefaultSignalsParams {
  const selectedId: string | number | null = state.selectedId;
  if (selectedId !== null && selectedId !== undefined && state.ids.length > 0) {
    // .indexOf types only work with string[]
    const deletedIdsContainSelectedId: boolean = (<string[]>deletedIds).indexOf(<string>selectedId) > -1;

    if (!deletedIdsContainSelectedId) {
      return state;
    }

    // .indexOf types only work with string[]
    const previousId: string | number | never = state.ids[(<string[]>state.ids).indexOf(<string>selectedId) - 1];
    const nextId: string | number | never = state.ids[(<string[]>state.ids).indexOf(<string>selectedId) + 1];

    if (previousId !== undefined) {
      return {...state, selectedId: previousId};
    } else if (nextId !== undefined) {
      return {...state, selectedId: nextId};
    } else {
      return {...state, selectedId: null};
    }
  }

  return state;
}

function defaultIdSelector<T>(entity: T | any): string {
  return entity['id'];
}

export class NgPatEntityStore<T> {
  componentStore: ComponentStore<SignalsEntityState<T>> = new ComponentStore();
  adapter: EntityAdapter<T>;

  // readonly state: Signal<SignalsEntityState<T>> = <
  //   Signal<SignalsEntityState<T>>
  // >toSignal(this.componentStore.state$);

  idSelector: IdSelector<T> = defaultIdSelector;
  sortComparer: false | Comparer<T> = false;

  // Used to clear state
  defaultProps = {};

  private _selectAll$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  selectAll$ = this._selectAll$.asObservable();

  private _selectEntities$: BehaviorSubject<Dictionary<T>> = new BehaviorSubject<Dictionary<T>>({});
  selectEntities$ = this._selectEntities$.asObservable().pipe(
    filter((all: Dictionary<T>) => {
      return Object.values(all).length > 0;
    })
  );

  private _selectIds$: BehaviorSubject<string[] | number[]> = new BehaviorSubject<string[] | number[]>([]);
  selectIds$ = this._selectIds$.asObservable().pipe(filter((all: string[] | number[]) => all.length > 0));

  private _selectTotal$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  selectTotal$ = this._selectTotal$.asObservable();

  constructor(additionalProps = {}, _options?: SignalEntityOptions<T>) {
    this.defaultProps = {
      selectedId: null,
      isLoaded: false,
      isLoading: true,
      error: null,
      ...additionalProps
    };

    if (_options && _options.selectId) {
      this.idSelector = _options.selectId;
    }

    if (_options && _options.sortComparer) {
      this.sortComparer = _options.sortComparer;
    }

    this.adapter = createEntityAdapter<T>({
      selectId: this.idSelector,
      sortComparer: this.sortComparer
    });

    this.componentStore.setState(
      this.adapter.getInitialState(<SignalsEntityState<T>>{
        ids: [],
        entities: {},
        selectedId: null,
        isLoaded: false,
        isLoading: true,
        error: null,
        ...additionalProps
      })
    );

    const {selectAll, selectEntities, selectIds, selectTotal} = this.adapter.getSelectors();

    this.componentStore.select(selectIds).subscribe({
      next: (ids: string[] | number[]) => {
        this._selectIds$.next(ids);
      }
    });

    this.componentStore.select(selectAll).subscribe({
      next: (entities: T[]) => {
        this._selectAll$.next(entities);
      }
    });

    this.componentStore.select(selectEntities).subscribe({
      next: (entities: Dictionary<T>) => {
        this._selectEntities$.next(entities);
      }
    });

    this.componentStore.select(selectTotal).subscribe({
      next: (total: number) => {
        this._selectTotal$.next(total);
      }
    });
  }

  selectId(id: string | number) {
    this.componentStore.patchState({selectedId: id});
  }

  /**
   * Selects the first entity in the collection if no entity is selected.
   */
  selectFirstIdIfNoIdSelected(): void {
    const selectedId: string | number | null = this.componentStore.state().selectedId;
    const ids: string[] | number[] = this.componentStore.state().ids;
    if (ids.length > 0) {
      if (selectedId !== null && selectedId !== undefined) {
        this.selectId(ids[0]);
      }
    }
  }

  addOne = this.componentStore.updater((state: SignalsEntityState<T>, value: T): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.addOne(value, state));
  });

  setOne = this.componentStore.updater((state: SignalsEntityState<T>, value: T): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.setOne(value, state));
  });

  upsertOne = this.componentStore.updater((state: SignalsEntityState<T>, value: T): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.upsertOne(value, state));
  });

  addMany = this.componentStore.updater((state: SignalsEntityState<T>, value: T[]): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.addMany(value, state));
  });

  upsertMany = this.componentStore.updater((state: SignalsEntityState<T>, value: T[]): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.upsertMany(value, state));
  });

  updateOne = this.componentStore.updater((state: SignalsEntityState<T>, value: Update<T>): SignalsEntityState<T> => {
    return this.adapter.updateOne(value, state);
  });

  mapOne = this.componentStore.updater(
    (state: SignalsEntityState<T>, entityMap: EntityMapOne<T>): SignalsEntityState<T> => {
      return this.adapter.mapOne(entityMap, state);
    }
  );

  map = this.componentStore.updater((state: SignalsEntityState<T>, entityMap: EntityMap<T>): SignalsEntityState<T> => {
    return this.adapter.map(entityMap, state);
  });

  deleteOne = this.componentStore.updater((state: SignalsEntityState<T>, id: string): SignalsEntityState<T> => {
    return selectPreviousIdIfCurrentDeleted(this.adapter.removeOne(id, state), [id]);
  });

  removeMany = this.componentStore.updater((state: SignalsEntityState<T>, ids: string[]): SignalsEntityState<T> => {
    return selectPreviousIdIfCurrentDeleted(this.adapter.removeMany(ids, state), ids);
  });

  deleteMany = this.componentStore.updater((state: SignalsEntityState<T>, id: string[]): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.removeMany(id, state));
  });

  setAll = this.componentStore.updater((state: SignalsEntityState<T>, value: T[]): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.setAll(value, state));
  });

  setMany = this.componentStore.updater((state: SignalsEntityState<T>, value: T[]): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.setMany(value, state));
  });

  updateMany = this.componentStore.updater(
    (state: SignalsEntityState<T>, value: Update<T>[]): SignalsEntityState<T> => {
      return this.adapter.updateMany(value, state);
    }
  );

  removeOne = this.componentStore.updater((state: SignalsEntityState<T>, id: string): SignalsEntityState<T> => {
    return selectFirstIdIfNoIdSelected(this.adapter.removeOne(id, state));
  });

  removeAll = this.componentStore.updater((state): SignalsEntityState<T> => {
    return this.adapter.removeAll({
      ...state,
      ...this.defaultProps
    });
  });

  // selectAll$(): Observable<T[]> {
  //   if (this.adapter) {
  //     const {selectAll} = this.adapter.getSelectors();
  //     return this.componentStore.select(selectAll);
  //   }
  //
  //   return of([]);
  // }

  // selectAll: Signal<T[]> = <Signal<T[]>>toSignal(this.selectAll$);

  // selectEntities$(): Observable<Dictionary<T>> {
  //   const {selectEntities} = this.adapter.getSelectors();
  //   return this.componentStore.select(selectEntities);
  // }

  // selectEntities: Signal<Dictionary<T>> = <Signal<Dictionary<T>>>(
  //   toSignal(this.selectEntities$)
  // );

  // selectIds$(): Observable<string[] | number[]> {
  //   const {selectIds} = this.adapter.getSelectors();
  //   return this.componentStore.select(selectIds);
  // }

  // selectIds: Signal<string[] | number[]> = <Signal<string[] | number[]>>(
  //   toSignal(this.selectIds$)
  // );

  // selectTotal$(): Observable<number> {
  //   const {selectTotal} = this.adapter.getSelectors();
  //   return this.componentStore.select(selectTotal);
  // }

  // selectTotal: Signal<number> = <Signal<number>>toSignal(this.selectTotal$);

  selectedId$(): Observable<string | number | null> {
    return this.componentStore.select((state: SignalsEntityState<T>) => {
      return state.selectedId;
    });
  }

  // selectedId: Signal<string | number | null> = <Signal<string | number | null>>(
  //   toSignal(this.selectedId$())
  // );

  // selectedEntity$ = this.componentStore.select((state: SignalsEntityState<T>) => {
  //   if (state.selectedId) {
  //     return state.entities[state.selectedId];
  //   }
  //   return null;
  // });

  selectedEntity$(): Observable<T | undefined | null> {
    return this.componentStore.select((state: SignalsEntityState<T>) => {
      if (state.selectedId) {
        return state.entities[state.selectedId];
      }
      return null;
    });
  }

  // selectedEntity: Signal<T | undefined | null> = <Signal<T | undefined | null>>(
  //   toSignal(this.selectedEntity$())
  // );

  selectItemById$(id: string | number): Observable<T | undefined> {
    return <Observable<T | undefined>>this.componentStore.select((state: SignalsEntityState<any>) => {
      return state.entities[id];
    });
  }

  // selectItemById(id: string | number): Signal<T | undefined> {
  //   return toSignal(this.selectItemById$(id));
  // }

  clear(): void {
    // state state
    const state: SignalsEntityState<T> = this.componentStore.state();
    // clear state
    this.componentStore.setState(
      this.adapter.removeAll({
        ...state,
        ...this.defaultProps
      })
    );
  }

  has$ = (id: string): Observable<boolean> => {
    return this.selectItemById$(id).pipe(
      map((found: T | null | undefined) => {
        return found !== null && found !== undefined;
      })
    );
  };

  // has: (id: string) => Signal<boolean> = (id: string): Signal<boolean> => {
  //   return <Signal<boolean>>toSignal(this.has$(id));
  // };

  hasEntity$ = (entity: T): Observable<boolean> => {
    return this.selectItemById$(this.idSelector(entity)).pipe(
      map((found: T | null | undefined) => {
        return found !== null && found !== undefined;
      })
    );
  };

  // hasEntitySig: (entity: T) => Signal<boolean> = (
  //   entity: T
  // ): Signal<boolean> => {
  //   return <Signal<boolean>>toSignal(this.hasEntity$(entity));
  // };

  // hasEntity(entity: T): boolean {
  //   // id of entity
  //   const id: string | number = this.idSelector(entity);
  //   // state entity from store
  //   const found: T | null | undefined = this.selectItemById(id)();
  //
  //   // return true if entity exists in store
  //   return found !== null && found !== undefined;
  // }

  /**
   * Selects an entity by its id.
   * @param id
   */
  // selectById(id: string | number): T | undefined {
  //   return this.componentStore.state().entities[id];
  // }

  selectById$(id: string | number | null): Observable<T | null | undefined> {
    if (id !== null && id !== undefined) {
      return this.componentStore.select((state: SignalsEntityState<T>) => {
        return state.entities[id];
      });
    }

    return of(null);
  }

  setError(error: any) {
    this.componentStore.patchState({error});
  }

  /**
   * Selects the first entity in the collection.
   */
  selectFirstId(): void {
    const ids: string[] | number[] = this.componentStore.state().ids;
    if (ids.length > 0) {
      this.selectId(ids[0]);
    }
  }

  /**
   * Selects the next entity in the collection or first if the current entity is the last.
   */
  next(): void {
    const selectedId: string | number | null = this.componentStore.state().selectedId;
    const ids: string[] | number[] = this.componentStore.state().ids;
    if (ids.length > 0) {
      if (ids.length > 0) {
        if (selectedId !== null && selectedId !== undefined) {
          const index = ids.findIndex((i: string | number) => i === selectedId);
          if (index !== -1) {
            const nextIndex = index + 1;
            if (nextIndex < ids.length) {
              this.selectId(ids[nextIndex]);
            } else {
              this.selectId(ids[0]);
            }
          } else {
            this.selectId(ids[0]);
          }
        } else {
          this.selectId(ids[0]);
        }
      }
    }
  }

  /**
   * Selects the previous entity in the collection or last if the current entity is the first.
   */
  previous(): void {
    const selectedId: string | number | null = this.componentStore.state().selectedId;
    const ids: string[] | number[] = this.componentStore.state().ids;
    if (ids.length > 0) {
      if (selectedId !== null && selectedId !== undefined) {
        const index = ids.findIndex((i: string | number) => i === selectedId);
        if (index !== -1) {
          const previousIndex = index - 1;
          if (previousIndex >= 0) {
            this.selectId(ids[previousIndex]);
          } else {
            this.selectId(ids[ids.length - 1]);
          }
        } else {
          this.selectId(ids[ids.length - 1]);
        }
      } else {
        this.selectId(ids[ids.length - 1]);
      }
    }
  }

  /**
   * Compare an entity to an entity in the store
   * with the same id.
   *
   * @param updatedEntity
   * @param compareFn
   */
  compare$(updatedEntity: T | any, compareFn: (updatedEntity: T, cachedEntity: T) => boolean) {
    return this.selectItemById$(updatedEntity[this.idSelector(updatedEntity)]).pipe(
      map((found: T | undefined) => {
        if (found) {
          return compareFn(updatedEntity, found);
        }

        return false;
      })
    );
  }

  selectIsFirstEntitySelected$(): Observable<boolean> {
    return this.componentStore.select((state: SignalsEntityState<T>) => {
      return state.selectedId === state.ids[0];
    });
  }

  // selectIsFirstEntitySelected: Signal<boolean> = <Signal<boolean>>(
  //   toSignal(this.selectIsFirstEntitySelected$())
  // );

  selectIsLastEntitySelected$(): Observable<boolean> {
    return this.componentStore.select((state: SignalsEntityState<T>) => {
      return state.selectedId === state.ids[state.ids.length - 1];
    });
  }

  // selectIsLastEntitySelected: Signal<boolean> = <Signal<boolean>>(
  //   toSignal(this.selectIsLastEntitySelected$())
  // );

  /**
   * Returns entities that have been deleted from the store
   * for post-processing.
   *
   * Rather than using and effect to process deleted entities,
   * this method allows you to process deleted entities in other
   * locations in the app such as other feature services or components.
   */
  deletedEntities$(): Observable<T[]> {
    let currentEntities: Dictionary<T> = {};

    return this.componentStore.select((state: SignalsEntityState<T>) => {
      const entities = state.entities;

      const deletedEntities = Object.keys(currentEntities).reduce((result: {[key: string]: T}, key: string) => {
        if (!entities[key] && currentEntities[key]) {
          result[key] = <T>currentEntities[key];
        }
        return result;
      }, {});

      currentEntities = {
        ...entities
      };

      return Object.values(deletedEntities);
    });
  }

  // deletedEntities: Signal<T[]> = <Signal<T[]>>toSignal(this.deletedEntities$());
}
