import {Injectable} from '@angular/core';
import {NgPatFirestoreCollectionQuery, NgPatFirestoreService} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatMonitorAccounts,
  NgPatServiceConnector,
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '@ngpat/store';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Member} from '../../+members/members.model';
import {selectAllMemberss} from '../../+members/members.selectors';
import {CollaborativeProject} from '../../+project/project.model';
import {Quiz, TakeQuizResult} from '../../+quizzes/quiz.model';
import {firestoreQuizGradesByProject} from '../../firebase/database-paths';
import {selectHasActiveSubscription} from '../../subscription/subscription.selectors';
import {Classroom} from '../classroom.model';
import {highestQuizGradeByStudent} from './class-grades.fns';
import {StudentGrade} from './class-grades.model';

export interface ClassQuizGradesState extends EntityState<TakeQuizResult> {
  classroom: Classroom | null;
  quiz: Quiz | null;
}

@Injectable()
export class ClassQuizGradesService
  extends ComponentStore<ClassQuizGradesState>
  implements NgPatFirebaseConnectionService
{
  private _onDestroy$: Subject<boolean> = new Subject();
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private _queryCollaborationService: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  private connectorRegistered = false;
  private _isConnected$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _user$: ReplaySubject<NgPatAccountState> = new ReplaySubject<NgPatAccountState>(1);
  classroom!: Classroom;
  quiz!: Quiz;

  connectionKey = null;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(
      createEntityAdapter<TakeQuizResult>().getInitialState({
        classroom: null,
        quiz: null
      })
    );

    const that = this;
    this._queryCollaborationService = new NgPatFirestoreCollectionQuery<TakeQuizResult>(
      {
        queryMember: false,
        upsertManyUpdater: (questions: TakeQuizResult[]) => that.upsertMany(questions),
        updateManyUpdater: (questions: TakeQuizResult[]) => that.updateMany(aggregateUpdates(questions)),
        deleteManyUpdater: (ids: string[]) => that.deleteMany(ids)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );
  }

  readonly selectAll$: Observable<TakeQuizResult[]> = this.select((state: ClassQuizGradesState) => {
    const {selectAll} = this._adapter.getSelectors();
    return selectAll(state);
  });

  readonly selectQuiz$: Observable<Quiz | null> = this.select((state: ClassQuizGradesState) => state.quiz);

  readonly students$: Observable<StudentGrade[]> = this.select(
    this.store.pipe(select(selectAllMemberss)),
    this.store.pipe(select(selectHasActiveSubscription)),
    this.store.pipe(select(selectNgPatMentorAccountsDict)),
    this.store.pipe(select(selectNgPatLoggedInUID)),
    this.selectAll$,
    this.selectQuiz$,
    (
      members: Member[],
      isMentor: boolean,
      mentorAccts: NgPatMonitorAccounts,
      loggedInUID: string | null,
      quizResults: TakeQuizResult[],
      quiz: Quiz | null
    ) => {
      let _members: Member[] = [];

      if (isMentor) {
        _members = members.filter((m: Member) => mentorAccts[m.uid] || m.uid === loggedInUID);
      }

      return _members
        .filter(
          (m: Member) => m.projects[this.classroom.id] // has this class
          // (m.projects[this.studyGroup.id].role === ROLES.Student || // is a student of this class
          //   m.projects[this.studyGroup.id].role === ROLES.Mentor) // is a mentor of this class
        )
        .map((student: Member) => {
          const attempts = quizResults.filter((r: TakeQuizResult) => r.createdByUID === student.uid).length;

          const grade: StudentGrade = {
            uid: student.uid,
            student,
            quizName: quiz?.name,
            quizID: quiz?.id,
            result: highestQuizGradeByStudent(student, quizResults),
            quizTaken: attempts > 0,
            attempts
          };

          return {
            ...grade,
            quizTaken: grade.quizTaken !== null
          };
        });
    }
  );

  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  readonly updateMany = this.updater((state, questions: Update<TakeQuizResult>[]) => {
    return this._adapter.updateMany(questions, state);
  });

  readonly deleteMany = this.updater((state, ids: string[]) => this._adapter.removeMany(ids, state));

  readonly addClassAndQuiz = this.updater((state, {classroom, quiz}: {classroom: Classroom; quiz: Quiz}) => {
    return {
      ...state,
      classroom,
      quiz
    };
  });

  init(c: Classroom, q: Quiz) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.classroom = c;
    this.quiz = q;

    this.addClassAndQuiz({classroom: c, quiz: q});

    this.connection.setConnectionKey(this.getKey(c));
  }

  onConnect(user: NgPatAccountState) {
    if (this.classroom && user.uid) {
      this._isConnected$.next(true);
      const _path = firestoreQuizGradesByProject(this.classroom, user.uid);
      this._path$.next(_path);
      this._queryCollaborationService.onConnect(_path, null, user.uid, [where('quizID', '==', this.quiz.id)]);
      this._user$.next(user);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    if (this.connection) {
      this.connection.deleteKey();
    }
    this._isConnected$.next(false);
    this._queryCollaborationService.onDisconnect(user.uid);
  }

  onDestroy() {
    this.connection.destroy();
    this._onDestroy$.next(true);
  }

  private getKey(q: CollaborativeProject) {
    return `${q.id}-${q.parentProjectID}-grade`;
  }
}
