import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'dlc-sidenav-content-footer,[dlc-sidenav-content-footer], [dlcSidenavContentFooter]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlc-sidenav-content-footer.component.html',
  styleUrl: './dlc-sidenav-content-footer.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-sidenav-content-footer'
  }
})
export class DlcSidenavContentFooterComponent {}
