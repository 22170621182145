import {CopyAssetToPathParams} from '@gigasoftware/shared/entities';
import {ReferenceLink} from '@gigasoftware/shared/ui-design-library';
import {uuid} from '@ngpat/fn';
import {DEFAULT_PLATFORM_PROJECT_TYPE_DICT, Project} from '../+project/project.model';
import {
  firestorePublishedQuizQuestionDoc,
  firestoreQuizQuestionByProjectAndQuestionId
} from '../firebase/database-paths';
import {createProject} from '../fns/entity.fns';
import {
  Answer,
  CreateReviewQuizFromWrongAnswers,
  Question,
  QUESTION_TYPE,
  QuestionWithAnswer,
  QuestionWithPath,
  Quiz,
  QuizGrade,
  TakeQuizResult
} from './quiz.model';

export function createQuestion(quiz: Quiz, questionType: number): Question {
  return {
    id: uuid(),
    questionType,
    quizID: quiz.id,
    answers: {},
    imagePath: null,
    question: '',
    referenceLink: '',
    referenceLinks: [],
    placeholder: 'Enter Question'
  };
}

export function createTruFalseQuestion(quiz: Quiz, questionType: number): Question {
  const q: Question = {
    ...createQuestion(quiz, questionType),
    answers: {}
  };

  const trueAnswer: Answer = {
    id: uuid(),
    isCorrect: false,
    imagePath: null,
    answerText: 'Do Not Display',
    trueFalseAnswer: true
  };

  q.answers[trueAnswer.id] = trueAnswer;

  return q;
}

export function selectCorrectAnswer<T>(question: Question): Answer | null | undefined {
  return Object.keys(question.answers).reduce((a: Answer | null | undefined, key: string) => {
    if (!a && question.answers[key] && question.answers[key].isCorrect) {
      return question.answers[key];
    }
    return a;
  }, null);
}

export function selectTrueFalseAnswer(question: Question): Answer | null | undefined {
  return Object.values(question.answers)[0];
}

export function createMultichoiceAnswer(): Answer {
  return {
    id: uuid(),
    isCorrect: false,
    imagePath: null,
    answerText: '',
    trueFalseAnswer: false
  };
}

export function createInitialQuizResult(): TakeQuizResult {
  return {
    id: '',
    quiz: <Quiz>createProject({
      id: '',
      name: '',
      projectTypeValue: DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id,
      description: '',
      isPrivate: true,
      isCollaborative: true
    }),
    quizID: '',
    isPrivate: true,
    createdByUID: '',
    questions: {},
    timestamp: 0
  };
}

export function createTestByQuiz(quiz: Quiz, questions: Question[], uid: string): TakeQuizResult {
  const _questions = questions.reduce((dict: {[key: string]: QuestionWithAnswer}, question: Question) => {
    dict[question.id] = {
      question,
      answers: null,
      isCorrect: null,
      isAnswered: false,
      questionID: question.id,
      timeToAnswerMS: 0
    };

    return dict;
  }, {});

  return {
    id: uuid(),
    quiz,
    quizID: quiz.id,
    isPrivate: true,
    createdByUID: uid,
    questions: _questions,
    timestamp: Date.now().valueOf()
  };
}

export function createQuizByTest(r: TakeQuizResult): CreateReviewQuizFromWrongAnswers {
  const quiz: Quiz = {
    ...r.quiz,
    id: uuid(),
    name: `Quiz Review: ${r.quiz.name}`
  };

  const questionsWithWrongAnswer: Question[] = updateQuestionsQuizId(
    Object.values(r.questions)
      .filter((q: QuestionWithAnswer) => !q.isAnswered || !q.isCorrect)
      .map((q: QuestionWithAnswer) => q.question),
    quiz
  );

  return {
    quiz,
    questions: questionsWithWrongAnswer
  };
}

export function calculateGrade(totalCorrect: number | undefined, totalAnswers: number | undefined): number {
  if (totalCorrect !== undefined && totalAnswers !== undefined && totalAnswers > 0) {
    return (totalCorrect / totalAnswers) * 100;
  }
  return 0;
}

export function createGradeChartData(r: TakeQuizResult): QuizGrade<TakeQuizResult> {
  const allAnswers: QuestionWithAnswer[] = Object.values(r.questions);

  const totalAnswers = allAnswers.length;
  const totalCorrect = allAnswers.filter((a: QuestionWithAnswer) => a.isCorrect).length;
  const grade = calculateGrade(totalCorrect, totalAnswers);
  const progress = parseInt(grade.toFixed(0));
  const totalTimeToTakeQuizMS = allAnswers.reduce((a: number, q: QuestionWithAnswer) => a + q.timeToAnswerMS, 0);

  return {
    id: r.id,
    name: r.quiz.name,
    result: r,
    timestamp: r.timestamp,
    totalCorrect,
    totalWrong: totalAnswers - totalCorrect,
    rawData: r,
    grade,
    totalTimeToTakeQuizMS,
    chartData: {
      min: 0,
      max: 100,
      units: '%',
      progress,
      status: progress < 70 ? 'error' : progress === 100 ? 'success' : 'warn'
    }
  };
}

export function updateQuestionsQuizId(questions: Question[], quiz: Quiz) {
  return questions.map((q: Question) => ({...q, quizID: quiz.id}));
}

// export function getImageAssetPathWithID(projects: Project[]) {
//   return projects
//     .filter((p: Project) => {
//       return p.imagePath !== null && p.imagePath !== undefined;
//     })
//     .map((p: Project) => {
//       return {};
//     });
// }

/**
 * Will return old and new path to copy assets in firebase storage
 * @param questions
 * @param quiz
 */
export function getImagePathsFromQuizAndQuestions(questions: (Quiz | Question)[]): CopyAssetToPathParams[] {
  return questions
    .filter((q: Quiz | Question) => {
      return q.imagePath !== null && q.imagePath !== undefined;
    })
    .map((q: Quiz | Question) => {
      const oldBasePath = (<string>q.imagePath).split('/')?.slice(0, -1)?.join('/');

      const oldID = (<string>q.imagePath).split('/')?.pop()?.split('.')?.shift();
      const replace = new RegExp(<string>oldID, 'g');
      const newImagePath = (<string>q.imagePath).replace(replace, q.id);
      const newBasePath = newImagePath.replace(<string>oldID, q.id);

      return <CopyAssetToPathParams>{
        oldID: <string>oldID,
        newID: q.id,
        oldBasePath,
        oldAssetImagePath: <string>q.imagePath,
        newBasePath,
        newAssetImagePath: newImagePath
      };
    });
}

/**
 * return imagePaths from array of Objects with property imagePath:
 * @param p - { imagePath: string | null | undefined }[]
 */
export function getImagePaths(
  p: {
    imagePath: string | null | undefined;
  }[]
): string[] {
  return p
    .filter((p: {imagePath: string | null | undefined}) => {
      return p.imagePath !== null && p.imagePath !== undefined;
    })
    .map((p: {imagePath: string | null | undefined}) => {
      return <string>p.imagePath;
    });
}

/**
 * Update imagePath with id of quiz or question
 * @param p - Project
 */
export function updateImagePathWithID<T>(p: {id: string; imagePath: string | null | undefined}): T {
  if (p.imagePath !== null && p.imagePath !== undefined) {
    const oldID = (<string>p.imagePath).split('/')?.pop()?.split('.')?.shift();

    const replace = new RegExp(<string>oldID, 'g');

    return <T>{
      ...p,
      imagePath: p.imagePath.replace(replace, p.id)
    };
  }

  return <T>{
    ...p
  };
}

/**
 * Update imagePath with id of quiz or question
 * @param projects - Project[]
 */
export function updateImagePathsWithID(projects: Project[]) {
  return projects.map(updateImagePathWithID);
}

export function combineQuestionsAndPath(questions: Question[], quiz: Quiz, uid: string): QuestionWithPath[] {
  return questions.map((question: Question) => {
    return <QuestionWithPath>{
      question,
      path: firestoreQuizQuestionByProjectAndQuestionId(quiz as Project, question.id, uid)
    };
  });
}

export function combinePublishedQuestionsAndPath(questions: Question[], quizID: string): QuestionWithPath[] {
  return questions.map((question: Question) => {
    return <QuestionWithPath>{
      question,
      path: firestorePublishedQuizQuestionDoc(quizID, question.id)
    };
  });
}

export function convertReferenceLinktStringToReferenceLink(link: ReferenceLink | string): ReferenceLink {
  if (typeof link === 'string') {
    return {
      url: link,
      label: link
    };
  }

  return link;
}

export function getReferenceLinksFromQuestion(question: Question): ReferenceLink[] {
  const links: ReferenceLink[] = [];

  if (question.referenceLinks !== null && question.referenceLinks !== undefined) {
    question.referenceLinks
      .filter((link: string | ReferenceLink | null | undefined) => {
        return link !== null && link !== undefined;
      })
      .map(convertReferenceLinktStringToReferenceLink)
      .forEach((link: ReferenceLink) => links.push(link));
  }

  if (question.referenceLink !== null && question.referenceLink !== undefined) {
    links.push(convertReferenceLinktStringToReferenceLink(question.referenceLink));
  }

  return links;
}

export function updateQuestionLinks(question: Question) {
  question.referenceLinks = getReferenceLinksFromQuestion(question);
  delete question.referenceLink;
  return {
    ...question
  };
}

export function convertQuestionLinkToLinksArray(questionsWithAnswerDict: {[questionID: string]: QuestionWithAnswer}): {
  [questionID: string]: QuestionWithAnswer;
} {
  // Rebuild dictionary
  return Object.keys(questionsWithAnswerDict).reduce(
    (a: {[questionID: string]: QuestionWithAnswer}, questionID: string) => {
      a[questionID] = {
        ...questionsWithAnswerDict[questionID],
        question: updateQuestionLinks(questionsWithAnswerDict[questionID].question)
      };

      return a;
    },
    {}
  );
}

export function getQuizByParentProjectIdOrAllQuizzes(
  quizzes: Quiz[],
  personalQuizzes: Quiz[],
  parentProjectID: string | null | undefined
): Quiz[] {
  if (parentProjectID) {
    return quizzes.filter((q: Quiz | undefined) => q !== undefined && q.parentProjectID === parentProjectID);
  }

  return personalQuizzes;
}

export function isTrueFalseQuestion(question: Question | null | undefined): boolean {
  return question ? question.questionType === QUESTION_TYPE.TRUE_FALSE : false;
}

export function isMultipleChoiceQuestion(question: Question | null | undefined): boolean {
  return question ? question.questionType === QUESTION_TYPE.MULTIPLE_CHOICE : false;
}
