import {Injectable} from '@angular/core';
import {DynamicStoreService, selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';

import {EMPTY} from 'rxjs';
import {switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Classroom} from '../+classrooms/classroom.model';
import {getClassroomByID} from '../+classrooms/classroom.selectors';
import {CollaborativeProject, Project} from '../+project/project.model';
import {ProjectService} from '../+project/project.service';
import {firestoreQueryPathByProject} from '../firebase/database-paths';
import {createCollaborativeProject, createProject} from '../fns/entity.fns';
import {createOwnerUserRole} from '../fns/user.fns';
import {PLATFORM_DYN_STORE} from '../models/dyn-store';
import {UserIdWithRole} from '../models/user.model.deprecated';
import {
  createQuizParams,
  createReviewQuizFromWrongAnswers,
  createSubQuizParams,
  deleteQuizs,
  updateFirestorePartialQuiz
} from './quiz.actions';
import {combineQuestionsAndPath} from './quiz.fns';
import {CreateSubQuizParams, QuestionWithPath, Quiz} from './quiz.model';
import {PartialQuizState} from './quiz.reducer';
import {QuizService} from './quiz.service';

@Injectable({
  providedIn: 'root'
})
export class QuizEffects {
  deleteDocs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteQuizs),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.store.pipe(
            select(getClassroomByID(action.ids[0])),
            switchMap((classRoom: Classroom | undefined) =>
              this.quizService.deleteQuizzesFirestore$(classRoom, action.ids, <string>account.uid)
            )
          )
        )
      ),
    {dispatch: false}
  );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateFirestorePartialQuiz),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this.quizService.updatePartialFirestore$(action.changes, action.quiz, account.uid)
        )
      ),
    {dispatch: false}
  );

  createReviewQuizFromWrongAnswers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createReviewQuizFromWrongAnswers),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, account]) => {
          const firestoreDestinationPath: string = firestoreQueryPathByProject(
            action.payload.quiz as Project,
            <string>account.uid
          );

          const questions: QuestionWithPath[] = combineQuestionsAndPath(
            action.payload.questions,
            action.payload.quiz,
            <string>account.uid
          );

          return this.quizService.setQuizAndQuestions$(firestoreDestinationPath, action.payload.quiz, questions).pipe(
            tap((q: Quiz) => {
              this.dynStore
                .getStore(PLATFORM_DYN_STORE.QUIZ_CREATED, {
                  isEmitterStore: true
                })
                .dispatch(q);
            })
          );
        })
      ),
    {dispatch: false}
  );

  createProjectParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createQuizParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            // const params: CreateQuizParams = {
            //   ...action.params
            // };
            // params.isCollaborative =
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id ||
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);
            let project: Project | CollaborativeProject = action.params.isCollaborative
              ? createCollaborativeProject(user, action.params)
              : createProject(action.params);

            project = {
              ...project,
              ...action.params
            };

            return this.projectService.createProject$(project, action.params, userAccount.uid);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  createSubProjectParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createSubQuizParams),
        withLatestFrom(this.store.pipe(select(selectNgPatUserAccount))),
        switchMap(([action, userAccount]) => {
          if (userAccount.uid) {
            const params: CreateSubQuizParams = {
              ...action.params
            };

            const baseParams: CreateSubQuizParams = {
              ...action.params,
              projectTypeValue: action.params.parentProject.projectType
            };

            // params.isCollaborative =
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id ||
            //   params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id
            //     ? false
            //     : params.isCollaborative;

            const user: UserIdWithRole = createOwnerUserRole(userAccount.uid);

            let project: Project | CollaborativeProject = params.isCollaborative
              ? createCollaborativeProject(user, params)
              : createProject(action.params);

            project = {
              ...project,
              ...action.params
            };

            return this.projectService.createSubProject$(project, baseParams, params, userAccount.uid);
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private store: Store<PartialQuizState>,
    private quizService: QuizService,
    private dynStore: DynamicStoreService,
    private projectService: ProjectService
  ) {}
}
