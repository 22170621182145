import {Capacitor} from '@capacitor/core';
import {EC_THEME_CONFIGS, NavItem} from '@gigasoftware/shared/domain';
import {getDefaultThemeConfig, ThemeConfig} from '@ngpat/utils';
import {Project} from '../+project/project.model';

export const uiFeatureKey = 'ui';

export interface HomeAndLogin {
  mobileHome: NavItem;
  login: NavItem;
  dashboardHome: NavItem;
}

export enum MobileHomeLayoutState {
  GRID = 'grid',
  LIST = 'list'
}

export interface NavItemDict {
  [key: string]: NavItem;
}

export interface UiState {
  /**
   * @description
   * ID of previous nav item. The page the user navigated from.
   *
   * Nav Item ID from apps/quiz-kit/quiz-kit-app/src/app/nav-item-dict.ts
   */
  previousNavItemID: string;

  /**
   * @description
   * Current Nav Item id.
   * The page the user is currently on.
   */
  currentNavItemID: string;

  currentQuizID: string | null;
  currentQuestionID: string | null;
  currentClassID: string | null;
  currentStudyGroupID: string | null;
  currentQuizReviewID: string | null;

  isLargeScreen: boolean;
  isMobile: boolean;
  isIOS: boolean;

  isPortrait: boolean;
  isLandscape: boolean;

  currentURL: string;

  /**
   * @deprecated
   */
  navParams: {[key: string]: string};

  leftSidenavOpen: boolean | null;
  currentTheme: ThemeConfig;
  themes: ThemeConfig[];
  isLoaded: boolean;
  mobileHomeLayoutState: MobileHomeLayoutState;
  selectedProject: Project | null;
  navItemEntities: NavItemDict;
  uiStateInitialized: boolean;
}

export interface PartialUiState {
  readonly [uiFeatureKey]: UiState;
}

export interface SelectedTheme {
  isLoaded: boolean;
  selectedTheme: ThemeConfig;
  themes: ThemeConfig[];
}

/**
 * Params are used to navigate to a page.
 * And are set by nav params route service.
 */
export interface UIParams {
  currentQuizID?: string | null;
  currentQuestionID?: string | null;
  currentAnswerID?: string | null;
  currentClassID?: string | null;
  currentStudyGroupID?: string | null;
  currentReviewID?: string | null;
}

export const initialUiState: UiState = {
  /** NAV.MOBILE_HOME_PAGE */
  currentNavItemID: '0',
  previousNavItemID: '0',
  currentStudyGroupID: null,
  currentClassID: null,
  currentQuizID: null,
  currentQuestionID: null,
  currentQuizReviewID: null,
  isLargeScreen: false,
  isMobile: false,
  isIOS: Capacitor.getPlatform() === 'ios',

  isPortrait: true,
  isLandscape: false,

  currentURL: '',

  navParams: {},

  leftSidenavOpen: null,
  isLoaded: false,
  mobileHomeLayoutState: MobileHomeLayoutState.GRID,
  currentTheme: getDefaultThemeConfig(EC_THEME_CONFIGS),
  themes: [...EC_THEME_CONFIGS],
  selectedProject: null,
  navItemEntities: {},
  uiStateInitialized: false
};

/**
 * See libs/evolving-cognition/domain/src/lib/+ui/ui.fns.ts:6
 */
export declare type UiStorage = Pick<
  UiState,
  | 'previousNavItemID'
  | 'currentNavItemID'
  | 'currentQuizID'
  | 'currentQuestionID'
  | 'currentClassID'
  | 'currentStudyGroupID'
  | 'currentQuizReviewID'
  | 'currentTheme'
  | 'isLargeScreen'
  | 'isMobile'
  | 'isIOS'
  | 'currentURL'
>;
