import {
  CreateProjectParams,
  createProjectParamsObject,
  DEFAULT_PLATFORM_PROJECT_TYPE_DICT,
  Project
} from '../../+project';
import {firestoreClassDoc, firestoreUserClassDoc} from './classes';
import {firestoreCourseDoc, firestoreUserCourseDoc} from './courses';
import {firestoreInvestigationDoc, firestoreUserInvestigationDoc} from './investigations';
import {BASE_COLLECTION_NAME} from './model';
import {firestoreQuizDoc, firestoreUserQuizDoc} from './quiz';
import {firestoreResearchDoc, firestoreUserResearchDoc} from './research';
import {firestoreStudyGroupDoc, firestoreUserStudyGroupDoc} from './study-groups';

export function firestoreProjectsCollection() {
  return `${BASE_COLLECTION_NAME.PROJECTS}`;
}
export function firestoreProjectDoc(projectId: string) {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectId}`;
}

export function firestoreProjectSubPath(params: CreateProjectParams, projectID: string) {
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
    return firestoreQuizDoc(projectID);
  }
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
    return firestoreCourseDoc(projectID);
  }
  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
    return firestoreResearchDoc(projectID);
  }

  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
    return firestoreClassDoc(projectID);
  }

  if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
    return firestoreInvestigationDoc(projectID);
  }

  return '';
}

/**
 * Project based on ProjectType and Path
 */
export function firestoreProjectPathByParams(params: CreateProjectParams, uid: string, projectID: string): string {
  if (params.isCollaborative) {
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
      return firestoreQuizDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
      return firestoreCourseDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
      return firestoreResearchDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
      return firestoreInvestigationDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
      return firestoreStudyGroupDoc(projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
      return firestoreClassDoc(projectID);
    }
  } else {
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.QUIZ.id) {
      return firestoreUserQuizDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.COURSE.id) {
      return firestoreUserCourseDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.RESEARCH.id) {
      return firestoreUserResearchDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.INVESTIGATION.id) {
      return firestoreUserInvestigationDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.STUDY_GROUP.id) {
      return firestoreUserStudyGroupDoc(uid, projectID);
    }
    if (params.projectTypeValue === DEFAULT_PLATFORM_PROJECT_TYPE_DICT.CLASS.id) {
      return firestoreUserClassDoc(uid, projectID);
    }
  }

  return '';
}

export function firestoreQueryPathByProject(p: Project, uid: string) {
  const params: CreateProjectParams = createProjectParamsObject(p);

  if (p.isCollaborative && p.parentProjectID && p.parentProjectID.length && p.parentProjectType !== null) {
    const parentParams: CreateProjectParams = {
      ...params,
      projectTypeValue: p.parentProjectType
    };

    return `${firestoreProjectPathByParams(parentParams, uid, p.parentProjectID)}/${firestoreProjectSubPath(params, p.id)}`;
  } else {
    return firestoreProjectPathByParams(params, uid, p.id);
  }
}
