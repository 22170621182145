import {Injectable, NgZone} from '@angular/core';
import {NgPatFirestoreCollectionQuery, NgPatFirestoreService} from '@ngpat/firebase';
import {aggregateUpdates, NgPatAccountState, NgPatFirebaseConnectionService, NgPatServiceConnector} from '@ngpat/store';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {firestoreQuizGradesByProject} from '../../firebase/database-paths';
import {FirestoreWebSocketConnectorService} from '../../firebase/firestore-web-socket-connector.service';
import {createGradeChartData} from '../quiz.fns';
import {Quiz, QuizGrade, TakeQuizResult} from '../quiz.model';
import {Project} from '../../+project/project.model';

export class QuizGradesService
  extends ComponentStore<EntityState<TakeQuizResult>>
  implements NgPatFirebaseConnectionService
{
  private _onDestroy$: Subject<boolean> = new Subject();
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private _queryCollaborationService: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _user$: ReplaySubject<NgPatAccountState> = new ReplaySubject<NgPatAccountState>(1);
  quiz!: Quiz;

  connectionKey = null;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(createEntityAdapter<TakeQuizResult>().getInitialState());

    const that = this;
    this._queryCollaborationService = new NgPatFirestoreCollectionQuery<TakeQuizResult>(
      {
        queryMember: false,
        upsertManyUpdater: (questions: TakeQuizResult[]) => that.upsertMany(questions),
        updateManyUpdater: (questions: TakeQuizResult[]) => that.updateMany(aggregateUpdates(questions)),
        deleteManyUpdater: (ids: string[]) => that.deleteMany(ids)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );
  }

  readonly grades$: Observable<QuizGrade<TakeQuizResult>[]> = this.select((state: EntityState<TakeQuizResult>) => {
    const that = this;

    const {selectAll} = this._adapter.getSelectors();

    return selectAll(state)
      .filter((r: TakeQuizResult) => {
        return r.quiz.id === that.quiz.id;
      })
      .map(createGradeChartData)
      .sort((a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) => b.timestamp - a.timestamp);
  });

  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });
  readonly updateMany = this.updater((state, questions: Update<TakeQuizResult>[]) => {
    return this._adapter.updateMany(questions, state);
  });
  readonly deleteMany = this.updater((state, ids: string[]) => this._adapter.removeMany(ids, state));

  // private getResultProject(): Observable<Project> {
  //   if (
  //     this.quiz.parentProjectID !== null &&
  //     this.quiz.parentProjectID !== undefined &&
  //     this.quiz.parentProjectType !== null &&
  //     this.quiz.parentProjectType !== undefined
  //   ) {
  //     return this.store.pipe(
  //       select(
  //         selectProjectByType(
  //           this.quiz.parentProjectType,
  //           this.quiz.parentProjectID
  //         )
  //       ),
  //       take(1)
  //     );
  //   } else {
  //     return of(this.quiz);
  //   }
  // }

  init(q: Quiz) {
    this.quiz = q;

    this.connection.setConnectionKey(this.getKey(q));
  }

  onConnect(user: NgPatAccountState) {
    if (this.quiz && user.uid) {
      const _path = firestoreQuizGradesByProject(this.quiz as Project, user.uid);
      this._path$.next(_path);
      this._queryCollaborationService.onConnect(_path, null, user.uid, [where('createdByUID', '==', user.uid)]);
      this._user$.next(user);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    this.connection.deleteKey();
    this._queryCollaborationService.onDisconnect(user.uid);
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
    this._user$.pipe(take(1)).subscribe((user: NgPatAccountState) => {
      this.onDisconnect(user);
    });
  }

  private getKey(q: Quiz) {
    return `${q.id}-${q.parentProjectID}-grade`;
  }
}

@Injectable()
export class QuizGradesCreatorService {
  private _onDestroy$: Subject<boolean> = new Subject();
  private _gradeService: QuizGradesService | undefined | null = null;

  grades$: ReplaySubject<QuizGrade<TakeQuizResult>[]> = new ReplaySubject<QuizGrade<TakeQuizResult>[]>(1);

  constructor(
    private store: Store,
    private _zone: NgZone,
    private customFirestoreService: NgPatFirestoreService,
    private connector: FirestoreWebSocketConnectorService
  ) {}

  get service(): QuizGradesService {
    if (!this._gradeService) {
      this._gradeService = new QuizGradesService(this.store, this.customFirestoreService);
    }

    return this._gradeService;
  }

  init(quiz: Quiz) {
    this.onDestroy();
    this.service.init(quiz);

    this.service.grades$.pipe(takeUntil(this._onDestroy$)).subscribe((_grades: QuizGrade<TakeQuizResult>[]) => {
      this.grades$.next([..._grades]);
    });
  }

  onDestroy() {
    if (this._gradeService) {
      this._onDestroy$.next(true);
      this._gradeService.onDestroy();
      this._gradeService = null;
    }
  }
}
