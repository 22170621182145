import {Injectable} from '@angular/core';
import {
  FirestoreCollectionQueryFactoryConfig,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector,
  selectNgPatLoggedInUID
} from '@ngpat/store';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {highestQuizGradeByStudent} from '../../+classrooms/class-grades/class-grades.fns';
import {
  StudentGrade,
  StudentQuizGrade,
  StudentQuizGradesTableData
} from '../../+classrooms/class-grades/class-grades.model';
import {Classroom} from '../../+classrooms/classroom.model';
import {MemberListItem} from '../../+members/members.model';
import {getStudentsByProjectID} from '../../+members/members.selectors';
import {firestoreQuizGradesByProject} from '../../firebase/database-paths';
import {selectHasActiveSubscription} from '../../subscription/subscription.selectors';
import {calculateGrade} from '../quiz.fns';
import {Quiz, TakeQuizResult} from '../quiz.model';
import {getQuizAssignedByParentIDAndQuizId, getQuizzesByParentID} from '../quiz.selectors';
import {Project} from '../../+project';

export interface ClassQuizStudentGradesState extends EntityState<TakeQuizResult> {
  classroom: Classroom | null;
  quiz: Quiz | null;
}

@Injectable()
export class ClassGradesOfQuizService
  extends ComponentStore<ClassQuizStudentGradesState>
  implements NgPatFirebaseConnectionService
{
  private _onDestroy$: Subject<boolean> = new Subject();
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private connectorRegistered = false;
  private _isConnected$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _user$: ReplaySubject<NgPatAccountState> = new ReplaySubject<NgPatAccountState>(1);
  private _classroom$: ReplaySubject<Classroom> = new ReplaySubject<Classroom>(1);
  private _quiz$: ReplaySubject<Quiz> = new ReplaySubject<Quiz>(1);

  private _queryFactory: FirestoreCollectionQueryFactoryConfig<TakeQuizResult>;
  private _queryCache: {
    [path: string]: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  } = {};

  studentGrades$: Observable<StudentQuizGrade[]>;
  studentGradesTableData$: Observable<StudentQuizGradesTableData[]>;

  connectionKey = null;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(
      createEntityAdapter<TakeQuizResult>().getInitialState({
        classroom: null,
        quiz: null
      })
    );

    const that = this;
    this._queryFactory = ngPatFirestoreCollectionQueryFactory<TakeQuizResult>(
      {
        queryMember: false,
        upsertManyUpdater: (questions: TakeQuizResult[]) => that.upsertMany(questions),
        updateManyUpdater: (questions: TakeQuizResult[]) => that.updateMany(aggregateUpdates(questions)),
        deleteManyUpdater: (ids: string[]) => that.deleteMany(ids)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );

    combineLatest([this._isConnected$, this._user$, this._classroom$])
      .pipe(
        switchMap(
          ([isConnected, user, classroom]: [boolean, NgPatAccountState, Classroom]): Observable<
            [boolean, NgPatAccountState, string[]]
          > => {
            return this.store.pipe(
              select(getQuizzesByParentID(classroom.id)),
              map((quizList: Quiz[]) => {
                const paths: string[] = quizList.map((quiz: Quiz) =>
                  firestoreQuizGradesByProject(quiz as Project, <string>user.uid)
                );

                return [isConnected, user, paths];
              })
            );
          }
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe(([isConnected, user, paths]: [boolean, NgPatAccountState, string[]]) => {
        if (isConnected) {
          const pathSet: Set<string> = new Set(paths);

          // Add Query connections
          for (let q = 0; q < paths.length; q++) {
            const path: string = paths[q];
            if (!that._queryCache[path]) {
              that._queryCache[path] = that._queryFactory.createFirestoreCollectionQuery();
              that._queryCache[path].onConnect(path, null, user.uid);
            }
          }

          // remove unused query connections
          Object.keys(that._queryCache).forEach((key: string) => {
            if (!pathSet.has(key)) {
              that._queryCache[key].onDisconnect(<string>user.uid);
              delete that._queryCache[key];
            }
          });
        }
      });

    this.studentGrades$ = combineLatest([this._classroom$, this._quiz$]).pipe(
      switchMap(([c, q]: [Classroom, Quiz]) =>
        combineLatest([
          this.store.pipe(select(getStudentsByProjectID(c.id))),
          this.store.pipe(select(getQuizAssignedByParentIDAndQuizId(c.id, q.id))),
          this.store.pipe(select(selectHasActiveSubscription)),
          this.store.pipe(select(selectNgPatLoggedInUID)),
          this._allGrades$
        ]).pipe(
          takeUntil(this._onDestroy$),
          map(
            ([members, quiz, loggedInUserIsStudent, loggedInUID, allClassGrades]: [
              MemberListItem[],
              Quiz | undefined,
              boolean,
              string | null,
              TakeQuizResult[]
            ]): StudentQuizGrade[] => {
              if (quiz !== null && quiz !== undefined) {
                return members
                  .filter((m: MemberListItem) => {
                    return loggedInUserIsStudent ? m.uid === loggedInUID : true;
                  })
                  .map((m: MemberListItem) => {
                    // const quizMap: MemberQuizMap = memberQuizMap(quiz);

                    const studentGrades = <StudentQuizGrade>{
                      uid: m.uid,
                      student: m.member
                    };

                    const attempts = allClassGrades.filter((r: TakeQuizResult) => r.createdByUID === m.uid).length;

                    const grade: StudentGrade = {
                      uid: m.uid,
                      student: m.member,

                      // NOTE: Get highest quiz grade
                      result: highestQuizGradeByStudent(
                        studentGrades.student,
                        allClassGrades.filter((a: TakeQuizResult) => quiz !== undefined && a.quiz.id === quiz.id)
                      ),
                      quizTaken: attempts > 0,
                      quizID: quiz.id,
                      quizName: quiz.name,
                      attempts
                    };

                    studentGrades.grade = grade;

                    return studentGrades;
                  });
              }

              return [];
            }
          )
        )
      )
    );

    this.studentGradesTableData$ = this.studentGrades$.pipe(
      map((grades: StudentQuizGrade[]) =>
        grades.map((g: StudentQuizGrade) => {
          const _g: StudentQuizGradesTableData = {
            student: g.student.username,
            grade: 0
          };

          _g.grade =
            g.grade.attempts === 0
              ? 0
              : calculateGrade(g.grade?.result?.totalCorrect, g.grade?.result?.result?.quiz?.totalQuestions);

          return _g;
        })
      )
    );
  }

  private _allGrades$: Observable<TakeQuizResult[]> = this.select((state: ClassQuizStudentGradesState) => {
    const {selectAll} = this._adapter.getSelectors();

    return selectAll(state);
  });

  readonly selectClassQuizzes$: Observable<Classroom | null> = this.select(
    (state: ClassQuizStudentGradesState) => state.classroom
  );

  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  readonly updateMany = this.updater((state, questions: Update<TakeQuizResult>[]) => {
    return this._adapter.updateMany(questions, state);
  });

  readonly deleteMany = this.updater((state, ids: string[]) => this._adapter.removeMany(ids, state));

  readonly addQuizAndClassroom = this.updater(
    (state: EntityState<TakeQuizResult>, add: {classroom: Classroom; quiz: Quiz}) => {
      return {
        ...state,
        ...add
      };
    }
  );

  init(classroom: Classroom, quiz: Quiz) {
    const that = this;

    this.addQuizAndClassroom({
      classroom,
      quiz
    });
    this._classroom$.next(classroom);
    this._quiz$.next(quiz);

    if (!this.connectorRegistered) {
      this.connectorRegistered = true;

      that.connection.setConnectionKey(this.getKey(classroom));
    }
  }

  onConnect(user: NgPatAccountState) {
    if (user.uid) {
      this._isConnected$.next(true);
      // const _path = firestoreQuizGradesByProject(this.quiz, user.uid);
      // this._path$.next(_path);
      this._user$.next(user);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    const that = this;
    that.connection.deleteKey();
    that._isConnected$.next(false);
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
    this._user$.pipe(take(1)).subscribe((user: NgPatAccountState) => {
      this.onDisconnect(user);
    });
  }

  private getKey(q: Classroom) {
    return `${q.id}-class-grades`;
  }
}
