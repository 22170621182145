import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserInvestigationCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.INVESTIGATIONS}`;
}

export function firestoreUserInvestigationDoc(uid: string, investigationID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}`;
}

export function firestoreInvestigationCollection() {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}`;
}

export function firestoreInvestigationDoc(investigationsID: string): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationsID}`;
}
