import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatProgressBarModule, ProgressBarMode} from '@angular/material/progress-bar';

@Component({
  selector: 'dlc-header-bar',
  standalone: true,
  imports: [CommonModule, MatProgressBarModule],
  templateUrl: './dlc-header-bar.component.html',
  styleUrl: './dlc-header-bar.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: `dlc-header-bar`,
    '[class.dlc-header-bar--border-lower-left-radius]': 'borderLowerLeftRadius() == true',
    '[class.dlc-header-bar--border-lower-right-radius]': 'borderLowerRightRadius() == true'
  }
})
export class DlcHeaderBarComponent {
  progressBarModeValue: WritableSignal<ProgressBarMode> = signal('indeterminate');

  @Input()
  set progressBarMode(mode: ProgressBarMode) {
    this.progressBarModeValue.set(mode);
  }

  progressBarValue = input(0);
  showProgressBar = input(false);

  borderLowerLeftRadius = input(true);
  borderLowerRightRadius = input(true);
}
