import {Injectable} from '@angular/core';
import {
  BASE_COLLECTION_NAME,
  Classroom,
  firestoreNotesCollection,
  firestoreUserNoteCollection,
  selectAddedAndDeletedClassrooms,
  selectAddedAndDeletedStudyGroups,
  StudyGroup
} from '@gigasoftware/evolving-cognition/domain';
import {GigaNoteDoc, NgPatAggregateFirebaseSnapshotChanges, NgPatFirestoreService} from '@ngpat/firebase';
import {deleteNotes, NgPatAddedAndDeletedEntities, selectNgPatLoggedInUID, upsertNotes} from '@ngpat/store';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Action, createAction, Store} from '@ngrx/store';
import {concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {NoteQueryStore} from './note-query.store';
import {NoteQueryStoreEntity} from './note-query.store.model';

const onNoteInit = createAction('[Note/API] On Note Init');

@Injectable()
export class NoteEffects implements OnInitEffects {
  getUserNotes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(onNoteInit),
      switchMap(() => {
        return this.store.select(selectNgPatLoggedInUID).pipe(
          filter((uid: string | null) => uid !== null && uid !== undefined && uid.length > 0),
          map((uid: string | null) => firestoreUserNoteCollection(<string>uid)),
          this.firestore.onSnapshotCollection$<GigaNoteDoc>(),
          concatMap((aggregate: NgPatAggregateFirebaseSnapshotChanges<GigaNoteDoc>) => {
            const actions = [];
            if (aggregate.added.length) {
              actions.push(upsertNotes({notes: [...aggregate.added]}));
            }

            if (aggregate.modified.length) {
              actions.push(upsertNotes({notes: [...aggregate.modified]}));
            }

            if (aggregate.removed.length) {
              actions.push(deleteNotes({ids: [...aggregate.removed]}));
            }

            return actions;
          })
        );
      })
    );
  });

  classNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedClassrooms).pipe(
        tap((data: NgPatAddedAndDeletedEntities<Classroom>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] = data.addedEntities.map((classroom: Classroom) => {
              return {
                id: classroom.id,
                firestoreCollectionPath: firestoreNotesCollection(BASE_COLLECTION_NAME.CLASSES, classroom.id)
              };
            });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] = data.deletedEntities.map((classroom: Classroom) => {
              return {
                id: classroom.id,
                firestoreCollectionPath: firestoreNotesCollection(BASE_COLLECTION_NAME.CLASSES, classroom.id)
              };
            });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  studyGroupNotes$ = createEffect(
    () =>
      this.store.select(selectAddedAndDeletedStudyGroups).pipe(
        tap((data: NgPatAddedAndDeletedEntities<StudyGroup>) => {
          if (data.addedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] = data.addedEntities.map((studyGroup: StudyGroup) => {
              return {
                id: studyGroup.id,
                firestoreCollectionPath: firestoreNotesCollection(BASE_COLLECTION_NAME.STUDY_GROUPS, studyGroup.id)
              };
            });
            this.noteQueryStore.addNoteQueryEngines(firestoreNotePaths);
          }

          if (data.deletedEntities.length) {
            const firestoreNotePaths: NoteQueryStoreEntity[] = data.deletedEntities.map((studyGroup: StudyGroup) => {
              return {
                id: studyGroup.id,
                firestoreCollectionPath: firestoreNotesCollection(BASE_COLLECTION_NAME.STUDY_GROUPS, studyGroup.id)
              };
            });
            this.noteQueryStore.removeNoteQueryEngines(firestoreNotePaths);
          }
        })
      ),
    {dispatch: false}
  );

  constructor(
    private firestore: NgPatFirestoreService,
    private store: Store,
    private actions$: Actions,
    private noteQueryStore: NoteQueryStore
  ) {}

  ngrxOnInitEffects(): Action {
    return onNoteInit();
  }
}
