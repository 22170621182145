import {NgPatFirestoreCollectionQuery, NgPatFirestoreService} from '@ngpat/firebase';
import {aggregateUpdates, NgPatAccountState, NgPatEntityStore} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {firestoreQuizGradesByProject} from '../../../firebase/database-paths';
import {createGradeChartData} from '../../quiz.fns';
import {HighestGradeTimeToTakeQuiz, Quiz, QuizGrade, TakeQuizResult} from '../../quiz.model';
import {Project} from '../../../+project/project.model';

export class GradesStore {
  private _gradeCache: NgPatEntityStore<TakeQuizResult> = new NgPatEntityStore();

  private _queryCollaborationService: NgPatFirestoreCollectionQuery<TakeQuizResult>;

  private _quiz: Quiz | null = null;

  get quiz(): Quiz | null {
    return this._quiz;
  }

  // grades: Signal<QuizGrade<TakeQuizResult>[]> = <
  //   Signal<QuizGrade<TakeQuizResult>[]>
  // >toSignal(
  //   this._gradeCache.selectAll$.pipe(
  //     map((selectAll: TakeQuizResult[]) => {
  //       return (
  //         selectAll
  //           // .filter((r: TakeQuizResult) => {
  //           //   return r.quiz.id === this._quiz.id;
  //           // })
  //           .map(createGradeChartData)
  //           .sort(
  //             (a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) =>
  //               b.timestamp - a.timestamp
  //           )
  //       );
  //     })
  //   )
  // );

  grades$: Observable<QuizGrade<TakeQuizResult>[]> = this._gradeCache.selectAll$.pipe(
    map((selectAll: TakeQuizResult[]) => {
      return (
        selectAll
          // .filter((r: TakeQuizResult) => {
          //   return r.quiz.id === this._quiz.id;
          // })
          .map(createGradeChartData)
          .sort((a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) => b.timestamp - a.timestamp)
      );
    })
  );

  /**
   * Number of times quiz has been taken.
   */
  // numberOfGrades: Signal<number> = <Signal<number>>(
  //   toSignal(this._gradeCache.selectTotal$)
  // );
  //
  numberOfGrades$: Observable<number> = this._gradeCache.selectTotal$;

  // highestGrade: Signal<number> = computed(() => {
  //   const grades = this.grades();
  //
  //   if (grades && grades.length > 0) {
  //     const sorted = grades.sort(
  //       (a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) =>
  //         b.grade - a.grade
  //     );
  //
  //     return sorted.shift()?.grade || 0;
  //   }
  //
  //   return 0;
  // });

  highestGrade$: Observable<number> = this.grades$.pipe(
    map((grades: QuizGrade<TakeQuizResult>[]) => {
      if (grades && grades.length > 0) {
        const sorted = grades.sort((a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) => b.grade - a.grade);

        return sorted.shift()?.grade || 0;
      }

      return 0;
    })
  );

  /**
   * Total time to take quiz at highest grade.
   */
  // lowestTimeToTakeQuizAtHighestGradeMS: Signal<number> = computed(() => {
  //   const grades = this.grades();
  //
  //   if (grades && grades.length > 0) {
  //     const sorted = grades.sort(
  //       (a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) =>
  //         b.grade - a.grade
  //     );
  //
  //     const highestGrades: QuizGrade<TakeQuizResult>[] = sorted.filter(
  //       (g: QuizGrade<TakeQuizResult>) => g.grade === sorted[0].grade
  //     );
  //
  //     const highestGradeToTakeQuiz: HighestGradeTimeToTakeQuiz = sorted.reduce(
  //       (a: HighestGradeTimeToTakeQuiz, g: QuizGrade<TakeQuizResult>) => {
  //         if (
  //           g.grade === a.highestGrade &&
  //           g.totalTimeToTakeQuizMS > a.totalTimeToTakeQuizMS
  //         ) {
  //           return {
  //             highestGrade: a.highestGrade,
  //             totalTimeToTakeQuizMS: g.totalTimeToTakeQuizMS
  //           };
  //         }
  //
  //         return a;
  //       },
  //       {
  //         highestGrade: sorted[0].grade,
  //         totalTimeToTakeQuizMS: sorted[0].totalTimeToTakeQuizMS
  //       }
  //     );
  //
  //     return highestGrades.reduce((a: number, g: QuizGrade<TakeQuizResult>) => {
  //       return Math.min(a, g.totalTimeToTakeQuizMS);
  //     }, highestGradeToTakeQuiz.totalTimeToTakeQuizMS);
  //   }
  //
  //   return 0;
  // });

  lowestTimeToTakeQuizAtHighestGradeMS$: Observable<number> = this.grades$.pipe(
    map((grades: QuizGrade<TakeQuizResult>[]) => {
      if (grades && grades.length > 0) {
        const sorted = grades.sort((a: QuizGrade<TakeQuizResult>, b: QuizGrade<TakeQuizResult>) => b.grade - a.grade);

        const highestGrades: QuizGrade<TakeQuizResult>[] = sorted.filter(
          (g: QuizGrade<TakeQuizResult>) => g.grade === sorted[0].grade
        );

        const highestGradeToTakeQuiz: HighestGradeTimeToTakeQuiz = sorted.reduce(
          (a: HighestGradeTimeToTakeQuiz, g: QuizGrade<TakeQuizResult>) => {
            if (g.grade === a.highestGrade && g.totalTimeToTakeQuizMS > a.totalTimeToTakeQuizMS) {
              return {
                highestGrade: a.highestGrade,
                totalTimeToTakeQuizMS: g.totalTimeToTakeQuizMS
              };
            }

            return a;
          },
          {
            highestGrade: sorted[0].grade,
            totalTimeToTakeQuizMS: sorted[0].totalTimeToTakeQuizMS
          }
        );

        return highestGrades.reduce((a: number, g: QuizGrade<TakeQuizResult>) => {
          return Math.min(a, g.totalTimeToTakeQuizMS);
        }, highestGradeToTakeQuiz.totalTimeToTakeQuizMS);
      }

      return 0;
    })
  );

  // lowestTimeToTakeQuizSeconds: Signal<number> = computed(() => {
  //   return this.lowestTimeToTakeQuizAtHighestGradeMS() / 1000;
  // });

  lowestTimeToTakeQuizSeconds$: Observable<number> = this.lowestTimeToTakeQuizAtHighestGradeMS$.pipe(
    map((lowestTimeToTakeQuizAtHighestGradeMS: number) => {
      if (lowestTimeToTakeQuizAtHighestGradeMS > 0) {
        return lowestTimeToTakeQuizAtHighestGradeMS / 1000;
      }

      return 0;
    })
  );

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    const that = this;
    this._queryCollaborationService = new NgPatFirestoreCollectionQuery<TakeQuizResult>(
      {
        queryMember: false,
        upsertManyUpdater: (questions: TakeQuizResult[]) => that._gradeCache.upsertMany(questions),
        updateManyUpdater: (questions: TakeQuizResult[]) => that._gradeCache.updateMany(aggregateUpdates(questions)),
        deleteManyUpdater: (ids: string[]) => that._gradeCache.deleteMany(ids)
        // logUpsert: true
      },
      store,
      customFirestoreService
    );
  }

  setQuiz(quiz: Quiz) {
    this._quiz = quiz;
  }

  getTakeQuizResultById$(id: string): Observable<TakeQuizResult | null | undefined> {
    return this._gradeCache.selectById$(id);
  }

  onConnect(user: NgPatAccountState) {
    if (user.uid && this.quiz) {
      const _path = firestoreQuizGradesByProject(this.quiz as Project, user.uid);
      // console.log(this._quiz.name);
      // console.log('quizID', this._quiz.id);
      // console.log('user.uid', user.uid);
      // console.log('onConnect', _path);
      // console.log('\n\n');
      this._queryCollaborationService.onConnect(_path, null, user.uid, [
        where('createdByUID', '==', user.uid),
        where('quizID', '==', this.quiz.id)
      ]);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    this._queryCollaborationService.onDisconnect(user.uid);
  }
}
