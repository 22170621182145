import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  Input,
  output,
  OutputEmitterRef,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Capacitor} from '@capacitor/core';
import {
  convertBlobToPngBlob,
  defaultImageUploaderConfig,
  getFileFromEventTarget,
  GsAssetService,
  GSFirebaseUploadImageWithDocConfig
} from '@gigasoftware/shared/media';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {DlcRoundedTextIconButtonComponent} from '../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcBlobImageDirective} from '../../image/blob/dlc-blob-image.directive';
import {ImageCropperDialogComponent} from '../../image/cropper/dialog/image-cropper-dialog.component';
import {ImageCroppedEvent} from '../../image/cropper/image-cropper/index';
import {InputFileComponent} from '../dlc-input-file/input-file.component';
import {getStandardInputFileTypeByString} from '../dlc-input-file/input-file.fns';

/**
 * Configuration for the input image component
 * Example:
 * {
 *   "filenameWithoutExtension": "abc",
 *   "imagePath": "ec/images/abc/abc.png",
 *   "uploadConfig": {
 *     "baseImageDirectory": "ec/images",
 *     "imageNameIsParentDirectory": true,
 *     "maxImageSizes": [
 *       600,
 *       400,
 *       200,
 *       100,
 *       50
 *     ],
 *     "firestoreDoc": {
 *       "docProperty": "imagePath",
 *       "firestoreDocPath": "studyGroups/123abc/quizzes/abc"
 *     }
 *   }
 * }
 */
export interface DlcInputImageConfig {
  filenameWithoutExtension: string;
  // full image path including extension
  // e.g. 'ec/images/1234567890.png'
  imagePath: string;
  uploadConfig: GSFirebaseUploadImageWithDocConfig;
}

export enum DlcInputImageStateEnum {
  UPLOADING = 'uploading',
  DOWNLOADING = 'downloading',
  EDIT = 'edit',
  DELETING = 'deleting',
  SAVE_CANCEL = 'save-cancel',
  ERROR = 'error',
  NO_IMAGE = 'no-image'
}

export interface DlcInputImageState {
  state: DlcInputImageStateEnum;
  hasImage: boolean;
  showUploadProgress: boolean;
  showDownloadProgress: boolean;
  showEditButtons: boolean;
  showDeleteProgress: boolean;
  showSaveCancelButtons: boolean;
  showError: boolean;
}

@Component({
  selector: 'dlc-input-image',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    DlcRoundedTextIconButtonComponent,
    InputFileComponent,
    DlcBlobImageDirective,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './dlc-input-image.component.html',
  styleUrls: ['./dlc-input-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'dlc-input-image dlc-input-image__container',
    '[class.dlc-input-image__show-image-container]': '!isMobile() || blobSignal() !== null',
    '[class.dlc-input-image__container--uploading]': 'actionBarState().showUploadProgress',
    '[class.dlc-input-image__container--edit]': 'actionBarState().showEditButtons',
    '[class.dlc-input-image__container--downloading]': 'actionBarState().showDownloadProgress',
    '[class.dlc-input-image__container--deleting]': 'actionBarState().showDeleteProgress',
    '[class.dlc-input-image__container--save-cancel]': 'actionBarState().showSaveCancelButtons',
    '[class.dlc-input-image__container--error]': 'actionBarState().showError'
  }
})
export class DlcInputImageComponent {
  private dialog: MatDialog = inject(MatDialog);
  public assetService: GsAssetService = inject(GsAssetService);

  downloadImagePath$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  blobSignal: WritableSignal<Blob | null> = signal(null);

  inputErrorSignal: WritableSignal<string | null> = signal(null);

  imageEvent: Event | null = null;

  @ViewChild('fileInput') fileInput: ElementRef | undefined;

  actionBarState: WritableSignal<DlcInputImageState> = signal({
    state: DlcInputImageStateEnum.NO_IMAGE,
    hasImage: false,
    showUploadProgress: false,
    showDownloadProgress: false,
    showEditButtons: false,
    showDeleteProgress: false,
    showSaveCancelButtons: false,
    showError: false
  });

  inputImageConfig: WritableSignal<DlcInputImageConfig> = signal({
    filenameWithoutExtension: '',
    imagePath: '',
    uploadConfig: defaultImageUploaderConfig
  });

  acceptImage = getStandardInputFileTypeByString('image');

  previousEditImage: Blob | null = null;

  @Input()
  set config(c: DlcInputImageConfig | null | undefined) {
    if (c) {
      this.inputImageConfig.set(c);
    }
  }

  // isMobile = this.store.selectSignal(selectNgPatIsMobile);
  isMobile = signal(Capacitor.isNativePlatform());

  /**
   * Path of original image uploaded
   */

  fileChange: OutputEmitterRef<Blob | null> = output();
  deleteImage: OutputEmitterRef<boolean> = output();
  uploadComplete: OutputEmitterRef<string> = output();
  uploadStart: OutputEmitterRef<string> = output();
  state: OutputEmitterRef<DlcInputImageState> = output();

  constructor() {
    effect(
      () => {
        const config = this.inputImageConfig();

        if (config.imagePath && config.imagePath.length > 0) {
          this.downloadImagePath$.next(config.imagePath);
        }
      },
      {allowSignalWrites: true}
    );

    this.downloadImagePath$
      .pipe(
        filter((imagePath: string | null) => {
          return imagePath !== null && imagePath.length > 0;
        }),
        map((imagePath: string | null) => {
          return imagePath as string;
        }),
        distinctUntilChanged()
      )
      .subscribe(async (imagePath: string) => {
        this.setActionBarToDownload();
        const blob: Blob = await this.assetService.downloadBlob(imagePath);
        this.blobSignal.set(blob);
        this.setActionBarToEdit();
      });

    this.assetService.onUploadStartHandler((filePath: string) => {
      this.uploadStart.emit(filePath);
    });
  }

  openFileBrowser() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onDeleteImageSet(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const config = this.inputImageConfig();

    if (config.imagePath && config.imagePath.length > 0) {
      // this.blobSignal.set(null);
      // this.deleteImage.emit(true);
      this.setActionBarToDeleteProgress();

      if (config.uploadConfig.imageNameIsParentDirectory) {
        this.assetService.deleteAssetSet(config.filenameWithoutExtension, config.uploadConfig).then(() => {
          this.blobSignal.set(null);
          this.deleteImage.emit(true);
          this.setActionBarToDefault();
        });
      }
    }
  }

  onCancelEdit(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.previousEditImage !== null) {
      this.blobSignal.set(this.previousEditImage);
    }

    if (this.blobSignal() !== null) {
      this.setActionBarToEdit();
    } else {
      this.setActionBarToDefault();
    }
  }

  onSaveEdit(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const pngBlob = this.blobSignal();
    this.previousEditImage = null;
    if (pngBlob) {
      this.uploadImages(pngBlob);
    }
  }

  editImage(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const file = this.blobSignal();
    this.previousEditImage = file;

    if (file) {
      const dialogRef: MatDialogRef<ImageCropperDialogComponent> = this.dialog.open(ImageCropperDialogComponent, {
        data: {
          imageFile: file
        }
      });

      dialogRef.afterClosed().subscribe(async (imageFile: ImageCroppedEvent | null) => {
        if (imageFile && imageFile.blob) {
          this.blobSignal.set(imageFile.blob);
          this.setActionBarToSaveCancel();
        }
      });
    }
  }

  onInputUrl() {
    this.setActionBarToDownload();
  }

  onInputError(error: string) {
    this.inputErrorSignal.set(error);
    this.setActionBarToError();
  }

  async onInputBlob(blob: Blob) {
    const pngBlob = await convertBlobToPngBlob(blob);
    if (pngBlob) {
      this.uploadImages(pngBlob);
    }
  }

  async onUpdateImage(event: Event) {
    const file: File | undefined = getFileFromEventTarget(event);

    if (file) {
      const pngBlob = await convertBlobToPngBlob(file);
      if (pngBlob) {
        this.uploadImages(pngBlob);
      }
    }
  }

  private uploadImages(pngBlob: Blob) {
    // this.uploadStart.emit();
    this.setActionBarToUploadProgress();
    this.blobSignal.set(pngBlob);
    // this.fileChange.emit(pngBlob);

    const config: DlcInputImageConfig = this.inputImageConfig();

    this.assetService
      .uploadImages(pngBlob, config.filenameWithoutExtension, config.uploadConfig)
      .then((pathOfImage: string) => {
        this.setActionBarToEdit();
        this.assetService.uploadProgress.set(0);
        // this.uploadPath.emit(uploadPath);
        this.uploadComplete.emit(pathOfImage);
      });
  }

  private setActionBarToUploadProgress() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.UPLOADING,
      hasImage: false,
      showUploadProgress: true,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToEdit() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.EDIT,
      hasImage: true,
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: true,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToDownload() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.DOWNLOADING,
      hasImage: false,
      showUploadProgress: false,
      showDownloadProgress: true,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToDeleteProgress() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.DELETING,
      hasImage: true,
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: true,
      showSaveCancelButtons: false,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToSaveCancel() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.SAVE_CANCEL,
      hasImage: true,
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: true,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToError() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.ERROR,
      hasImage: false,
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: true
    });

    this.state.emit(this.actionBarState());
  }

  private setActionBarToDefault() {
    this.actionBarState.set({
      state: DlcInputImageStateEnum.NO_IMAGE,
      hasImage: false,
      showUploadProgress: false,
      showDownloadProgress: false,
      showEditButtons: false,
      showDeleteProgress: false,
      showSaveCancelButtons: false,
      showError: false
    });

    this.state.emit(this.actionBarState());
  }
}
