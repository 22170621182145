import {Injectable} from '@angular/core';
import {Exists, NgPatFirestoreCollectionQuery, NgPatFirestoreService} from '@ngpat/firebase';
import {aggregateUpdates, NgPatAccountState, NgPatFirebaseConnectionService, NgPatServiceConnector} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {from, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Project} from '../+project/project.model';
import {
  firestoreQueryPathByProject,
  firestoreQuizCollection,
  firestoreUserQuizCollection
} from '../firebase/database-paths';
import {deleteQuizs, updateQuizs, upsertQuizs} from './quiz.actions';
import {combineQuestionsAndPath} from './quiz.fns';
import {CreateReviewQuizFromWrongAnswers, QuestionWithPath, Quiz} from './quiz.model';
import {quizFeatureKey} from './quiz.reducer';

@Injectable({
  providedIn: 'root'
})
export class QuizService implements NgPatFirebaseConnectionService {
  private _quizzezCollaborativeFirestore!: NgPatFirestoreCollectionQuery<Quiz>;
  private _quizzezPrivateFirestore!: NgPatFirestoreCollectionQuery<Quiz>;

  connectionKey = quizFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._quizzezCollaborativeFirestore = new NgPatFirestoreCollectionQuery<Quiz>(
      {
        queryMember: true,
        upsertManyAction: (quizs: Quiz[]) => upsertQuizs({quizs}),
        updateManyAction: (quizs: Quiz[]) => updateQuizs({quizs: aggregateUpdates(quizs)}),
        deleteManyAction: (ids: string[]) => deleteQuizs({ids})
      },
      this.store,
      this.customFirestoreService
    );

    this._quizzezPrivateFirestore = new NgPatFirestoreCollectionQuery<Quiz>(
      {
        queryMember: false,
        upsertManyAction: (quizs: Quiz[]) => upsertQuizs({quizs}),
        updateManyAction: (quizs: Quiz[]) => updateQuizs({quizs: aggregateUpdates(quizs)}),
        deleteManyAction: (ids: string[]) => deleteQuizs({ids})
      },
      this.store,
      this.customFirestoreService
    );
  }

  // deleteQuizFirestore$(quiz: Project | undefined, uid: string) {
  //   if (quiz) {
  //     const path: string = quiz.isPrivate
  //       ? firestoreUserQuizDoc(uid, quiz.id)
  //       : firestoreQuizDoc(quiz.id);
  //
  //     // const path = studyGroup.isPrivate ?
  //     return this.customFirestoreService.deleteDoc$(path);
  //   }
  //   return of(true);
  // }

  deleteQuizzesFirestore$(quiz: Project | undefined, ids: string[], uid: string) {
    if (quiz) {
      const isPrivate = quiz.isPrivate;
      const path: string = isPrivate ? firestoreUserQuizCollection(uid) : firestoreQuizCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  upsertReviewQuizFromWrongAnswers(p: CreateReviewQuizFromWrongAnswers, uid: string): Observable<Quiz> {
    const path: string = firestoreQueryPathByProject(p.quiz as Project, uid);

    const questions: QuestionWithPath[] = combineQuestionsAndPath(p.questions, p.quiz, uid);

    return this.customFirestoreService.set$(path, p.quiz).pipe(
      switchMap(() => {
        const batch = this.customFirestoreService.writeBatch();

        for (let q = 0; q < questions.length; q++) {
          const docRef = this.customFirestoreService.docRef(questions[q].path);
          batch.set(docRef, this.customFirestoreService.payloadForSet(questions[q].question));
        }

        return from(batch.commit()).pipe(map(() => p.quiz));
      })
    );
  }

  setQuizAndQuestions$(quizFirestorePath: string, quiz: Quiz, questions: QuestionWithPath[]) {
    return this.customFirestoreService.set$(quizFirestorePath, quiz).pipe(
      switchMap(() => {
        const batch = this.customFirestoreService.writeBatch();

        for (let q = 0; q < questions.length; q++) {
          const docRef = this.customFirestoreService.docRef(questions[q].path);
          batch.set(docRef, this.customFirestoreService.payloadForSet(questions[q].question));
        }

        return from(batch.commit()).pipe(map(() => quiz));
      })
    );
  }

  async setQuizAndQuestions(quizFirestorePath: string, quiz: Quiz, questions: QuestionWithPath[]) {
    await this.customFirestoreService.setDoc(quizFirestorePath, quiz);

    if (questions.length > 0) {
      const batch = this.customFirestoreService.writeBatch();

      for (let q = 0; q < questions.length; q++) {
        const docRef = this.customFirestoreService.docRef(questions[q].path);
        batch.set(docRef, this.customFirestoreService.payloadForSet(questions[q].question));
      }

      await batch.commit();
    }

    return quiz;
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._quizzezCollaborativeFirestore.onConnect(firestoreQuizCollection(), null, <string>user.uid);
    this._quizzezPrivateFirestore.onConnect(firestoreUserQuizCollection(<string>user.uid), null, null);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._quizzezCollaborativeFirestore.onDisconnect();
    this._quizzezPrivateFirestore.onDisconnect();
  }

  updatePartialFirestore$(changes: Partial<Quiz>, quiz: Quiz, uid: string | null): Observable<Quiz> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Quiz>(firestoreQueryPathByProject(quiz as Project, uid), changes)
        .pipe(map((r: Exists<Quiz>) => r.data));
    }

    return of(quiz);
  }
}
