import {selectNgPatAddedAndDeletedEntities} from '@ngpat/store';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as ClassroomReducer from './classroom.reducer';
import {Dictionary} from '@ngrx/entity';
import {Classroom} from './classroom.model';
import {convertCollaborativeProjectToMemberUIDProjectID} from '../+members/member.fns';

export const selectClassroomState = createFeatureSelector<ClassroomReducer.ClassroomState>(
  ClassroomReducer.classroomFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} = ClassroomReducer.classroomAdapter.getSelectors();

export const selectAllClassrooms = createSelector(selectClassroomState, selectAll);
export const selectClassroomEntities = createSelector(selectClassroomState, selectEntities);
export const selectClassroomIds = createSelector(selectClassroomState, selectIds);
export const selectClassroomTotal = createSelector(selectClassroomState, selectTotal);
export const selectedClassroomID = createSelector(
  selectClassroomState,
  (state: ClassroomReducer.ClassroomState) => state.selectedClassroomID
);

export const selectClassroomMemberMap = createSelector(
  selectAllClassrooms,
  convertCollaborativeProjectToMemberUIDProjectID
);

export const getClassroomByID = (id: string | null | undefined) =>
  createSelector(selectClassroomEntities, (entities: Dictionary<Classroom>) => {
    if (id) {
      return entities[<string>id];
    }
    return undefined;
  });

export const selectAddedAndDeletedClassrooms = selectNgPatAddedAndDeletedEntities<Classroom>(selectClassroomEntities);
