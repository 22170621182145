import {DEFAULT_NAV_ITEM_ID, NavItem} from '@gigasoftware/shared/domain';
import {NgPatMonitorAccounts, selectNgPatLoggedInUID, selectNgPatMentorAccountsDict} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Classroom} from '../+classrooms/classroom.model';
import {selectClassroomEntities} from '../+classrooms/classroom.selectors';
import {Member, MemberListItem} from '../+members/members.model';
import {selectAllMemberss} from '../+members/members.selectors';
import {Quiz} from '../+quizzes/quiz.model';
import {selectQuizEntities} from '../+quizzes/quiz.selectors';
import {NAV} from '../models/nav-item-dict';
import {EC_QUIZ_ROLES} from '../models/user.model.deprecated';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {getCurrentClassMembersWithoutMentors} from './ui.fns';
import {HomeAndLogin, SelectedTheme, uiFeatureKey, UiState} from './ui.model';

export const selectUiState = createFeatureSelector<UiState>(uiFeatureKey);

export const selectUiStateIsInitialized = createSelector(selectUiState, (state: UiState) => state.uiStateInitialized);

export const selectLeftSidenavOpen = createSelector(selectUiState, (state: UiState) => state.leftSidenavOpen);

export const selectUiStateIsLoaded = createSelector(selectUiState, (state: UiState) => state.isLoaded);

export const selectIsDesktop = createSelector(selectUiState, (state: UiState) => state.isLargeScreen);

export const selectIsMobile = createSelector(selectUiState, (state: UiState) => state.isMobile);

export const selectCurrentURL = createSelector(selectUiState, (state: UiState) => state.currentURL);

export const selectTheme = createSelector(
  selectUiState,
  (state: UiState): SelectedTheme => ({
    selectedTheme: state.currentTheme,
    themes: state.themes,
    isLoaded: state.isLoaded
  })
);

export const selectSelectedProject = createSelector(selectUiState, (state: UiState) => state.selectedProject);

export const selectCurrentNavItemID = createSelector(selectUiState, (state: UiState) => state.currentNavItemID);

export const selectNavItemEntities = createSelector(selectUiState, (state: UiState) => state.navItemEntities);

/**
 * Used at: apps/quiz-kit/src/app/app.component.ts:150
 *
 * @description
 * Selects the current nav item by the current nav item id.
 */
export const selectCurrentNavItemByCurrentNavItemId = createSelector(
  selectUiState,
  (state: UiState): NavItem | null => {
    if (state.currentNavItemID && state.navItemEntities[state.currentNavItemID]) {
      return state.navItemEntities[state.currentNavItemID];
    } else if (!state.currentNavItemID && state.navItemEntities['0']) {
      return state.navItemEntities['0'];
    } else {
      return null;
    }
  }
);

/**
 * @use use UiFacadeService.previousNavItem instead
 */
export const selectPreviousNavItemByCurrentNavId = createSelector(selectUiState, (state: UiState) => {
  const navItem = state.navItemEntities[state.currentNavItemID];

  // Previous nav item is the parent nav item.
  return navItem && state.navItemEntities
    ? state.navItemEntities[navItem.previousNavItemID]
    : state.navItemEntities[DEFAULT_NAV_ITEM_ID];
});

export const selectCurrentAndPreviousNavItemByCurrentNavId = createSelector(
  selectCurrentNavItemByCurrentNavItemId,
  selectPreviousNavItemByCurrentNavId,
  (current: NavItem | null, previous: NavItem | null): {current: NavItem | null; previous: NavItem | null} => {
    return {current, previous};
  }
);

/**
 * @description
 * Selects the current nav item by the current nav item id.
 * Used at: libs/web-platform/data-access/src/lib/+ui/ui.selectors.ts:80
 * @param navItemKey
 */
export const getNavItemByID = (navItemKey: string) =>
  createSelector(selectUiState, (state: UiState) => state.navItemEntities[navItemKey]);

export const getNavItemByURL = (url: string) =>
  createSelector(selectUiState, (state: UiState) => {
    return Object.values(state.navItemEntities).reduce((n: NavItem | null, c: NavItem) => {
      if (!n && c.url === url) {
        return c;
      }

      return n;
    }, null);
  });

/**
 * @deprecated use UiFacadeService.previousNavItem instead
 * @param key
 */
export const getParentNavItemByCurrentNavId = (key: string) =>
  createSelector(selectUiState, (state: UiState) => {
    const navItem = state.navItemEntities[key];
    const parent = state.navItemEntities[navItem.previousNavItemID];

    if (navItem.url !== parent.url) {
      return parent;
    }

    return null;
  });

export const getNavMatchesURLByNavKey = (url: string, navKey: string) =>
  createSelector(selectUiState, getNavItemByID(navKey), (state: UiState, nav: NavItem): boolean => {
    return url.includes(nav.url);
  });

export const selectNavMatchesCurrentUrl = (navKey: string) =>
  createSelector(selectCurrentURL, getNavItemByID(navKey), (url: string, nav: NavItem) => url.includes(nav.url));

/**
 * @deprecated TODO remove when footer is removed
 */
export const selectShowFooter = createSelector(
  selectCurrentURL,
  selectIsMobile,
  getNavItemByID(NAV.MOBILE_QUIZ_TEST_PAGE),
  getNavItemByID(NAV.MOBILE_QUIZ_STATS_PAGE),
  (url: string, isMobile: boolean, mobileQuizTestPage: NavItem, mobileQuizItemPage: NavItem) => {
    if (url && mobileQuizTestPage && mobileQuizTestPage.url) {
      return !url.includes(mobileQuizTestPage.url) && !url.includes(mobileQuizItemPage.url) && isMobile;
    }

    return false;
  }
);

/**
 * Used at: apps/quiz-kit/src/app/app.component.ts:150
 * libs/web-platform/data-access/src/lib/+ui/ui.selectors.ts:80
 */
export const selectNavItemsHomeLogin = createSelector(selectUiState, (state: UiState): HomeAndLogin => {
  return {
    mobileHome: state.navItemEntities[DEFAULT_NAV_ITEM_ID],
    login: state.navItemEntities[NAV.MOBILE_LOGIN_PAGE],
    dashboardHome: state.navItemEntities[NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE]
  };
});

/**
 * @use use UiFacadeService.currentNavItem instead
 */
export const selectCurrentNavItem = createSelector(selectUiState, (state: UiState) => {
  return state.navItemEntities[state.currentNavItemID]
    ? state.navItemEntities[state.currentNavItemID]
    : state.navItemEntities[DEFAULT_NAV_ITEM_ID];
});

// QUIZ
// QUIZ
// QUIZ
export const selectCurrentQuizID = createSelector(selectUiState, (state: UiState) => state.currentQuizID);

export const selectCurrentQuestionID = createSelector(selectUiState, (state: UiState) => state.currentQuestionID);

export const selectCurrentQuizReviewID = createSelector(selectUiState, (state: UiState) => state.currentQuizReviewID);

export const selectCurrentQuiz = createSelector(
  selectCurrentQuizID,
  selectQuizEntities,
  (currentQuizID: string | null, quizEntities: Dictionary<Quiz>): Quiz | null | undefined => {
    if (currentQuizID && quizEntities[currentQuizID]) {
      return quizEntities[currentQuizID];
    }
    return null;
  }
);

// CLASSROOM
// CLASSROOM
// CLASSROOM
export const selectCurrentClassroom = createSelector(
  selectUiState,
  selectClassroomEntities,
  (state: UiState, classroomEntities: Dictionary<Classroom>): Classroom | null | undefined => {
    if (state.currentClassID && classroomEntities[state.currentClassID]) {
      return state.currentClassID ? classroomEntities[state.currentClassID] : null;
    }

    return null;
  }
);

export const selectCurrentClassroomID = createSelector(selectUiState, (state: UiState) => state.currentClassID);

export const selectCurrentClassMembersWithoutMentors = createSelector(
  selectUiState,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  (
    state: UiState,
    loggedInUserIsMentor: boolean,
    mentorAccounts: NgPatMonitorAccounts,
    members: Member[]
  ): MemberListItem[] => {
    if (state.currentClassID) {
      return getCurrentClassMembersWithoutMentors(
        state,
        loggedInUserIsMentor,
        mentorAccounts,
        members,
        state.currentClassID
      );
    }

    return [];
  }
);

export const selectLoggedInUserCanInviteToClassroom = createSelector(
  selectCurrentClassroom,
  selectNgPatLoggedInUID,
  (classroom: Classroom | null | undefined, loggedInUID: string | null): boolean => {
    if (loggedInUID && classroom && classroom.members && classroom.members[loggedInUID]) {
      return classroom.members[loggedInUID].role === EC_QUIZ_ROLES.Owner;
    }
    return false;
  }
);

export const selectLoggedInUserCanInviteToCurrentClassroom = createSelector(
  selectCurrentClassroom,
  selectNgPatLoggedInUID,
  (classroom: Classroom | null | undefined, loggedInUID: string | null): boolean => {
    if (loggedInUID && classroom && classroom.members && classroom.members[loggedInUID]) {
      return (
        classroom.members[loggedInUID].role === EC_QUIZ_ROLES.Owner ||
        classroom.members[loggedInUID].role === EC_QUIZ_ROLES.Teacher
      );
    }
    return false;
  }
);

// STUDY GROUP
export const selectCurrentStudyGroupID = createSelector(selectUiState, (state: UiState) => state.currentStudyGroupID);

export const selectMobileHomeLayoutState = createSelector(
  selectUiState,
  (state: UiState) => state.mobileHomeLayoutState
);
