import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserResearchCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.RESEARCH}`;
}

export function firestoreUserResearchDoc(uid: string, researchID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.RESEARCH}/${researchID}`;
}

export function firestoreResearchCollection() {
  return `${BASE_COLLECTION_NAME.RESEARCH}`;
}
export function firestoreResearchDoc(researchID: string): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}`;
}
