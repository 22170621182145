import {ChangeDetectionStrategy, Component, input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {fadeInAnimation} from '../../../animations';
import {DlcSidenavContentFooterComponent} from './dlc-content-footer/dlc-sidenav-content-footer.component';

@Component({
  selector: 'dlc-sidenav-content',
  standalone: true,
  imports: [CommonModule, DlcSidenavContentFooterComponent],
  templateUrl: './dlc-sidenav-content.component.html',
  styleUrl: './dlc-sidenav-content.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  host: {
    '[@fadeInAnimation]': '',
    class: 'dlc-sidenav-content'
  }
})
export class DlcSidenavContentComponent {
  bottom = input('0');
}
