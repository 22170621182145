import {NavItem} from '@gigasoftware/shared/domain';
import {createAction, props} from '@ngrx/store';
import {MobileHomeLayoutState, NavItemDict, UIParams, UiState} from './ui.model';
import {Project} from '../+project/project.model';
import {ThemeConfig} from '@ngpat/utils';

export const initUiState = createAction('[Ui] Init UI State');

export const actionUiStateInitialized = createAction('[Ui] UI State Initialized');

export const loadUIToStore = createAction('[Ui] Load UI To Store', props<{payload: Partial<UiState>}>());
export const saveUIToStore = createAction('[Ui] Save UI To Store', props<{payload: Partial<UiState>}>());
export const saveUIThemes = createAction('[Ui] Save UI Themes To Store', props<{themes: string[]}>());

export const setCurrentTheme = createAction('[Ui] Select Theme', props<{currentTheme: ThemeConfig}>());

export const setThemes = createAction('[Ui] Set Themes', props<{themes: ThemeConfig[]}>());

export const setCurrentNavItemID = createAction('[Ui] Set Current Nav Item ID', props<{currentNavItemId: string}>());

// export const navigateAction = createAction(
//   '[Ui] Navigate',
//   props<{navItemKey: string}>()
// );

// export function navigate(navItemKey: string) {
//   return navigateAction({navItemKey});
// }

export const setIsDesktop = createAction('[Ui] Is Desktop', props<{isDesktop: boolean}>());

export const setIsMobile = createAction('[Ui] Is Mobile', props<{isMobile: boolean}>());

export const setCurrentURL = createAction('[Ui] Set Current URL', props<{currentURL: string}>());

export const setLayout = createAction(
  '[Ui] Set Layout',
  props<{isLargeScreen: boolean; isMobile: boolean; isIOS: boolean; isPortrait: boolean}>()
);

/**
 * @description
 * Update navigation state with params.
 * This action will be handled by the reducer.
 */
export const setNavigateWithParams = createAction(
  '[Ui] Set Navigate With Params',
  props<{
    previousNavItemID: string;
    currentNavItemID: string;
    navParams: UIParams;
    navItem?: NavItem;
  }>()
);

export const selectProjectAction = createAction('[Ui] Select Project', props<{selectedProject: Project}>());
export const loadNavItemEntities = createAction('[UI] Load Nav Item Entities', props<{navItemEntities: NavItemDict}>());
export const setParentNavID = createAction('[UI] Set Mentor Nav ID', props<{id: string}>());

export const resetNavigation = createAction('[UI] Reset Navigation');

export const setMobileHomeLayoutState = createAction(
  '[UI] Set Mobile Home Layout State',
  props<{state: MobileHomeLayoutState}>()
);
