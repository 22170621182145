import {BASE_COLLECTION_NAME} from './model';
import {Project} from '../../+project';
import {firestoreQueryPathByProject} from './projects';

export function firestoreQuizQuestionsByProject(p: Project, uid: string) {
  return `${firestoreQueryPathByProject(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}`;
}

export function firestoreQuizQuestionByProjectAndQuestionId(p: Project, questionID: string, uid: string) {
  return `${firestoreQueryPathByProject(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}/${questionID}`;
}

export function firestoreQuizQuestionsAnswersByProject(p: Project, uid: string, questionId: string) {
  return `${firestoreQueryPathByProject(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}/${questionId}/${BASE_COLLECTION_NAME.ANSWERS}`;
}
