import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, signal, ViewEncapsulation, WritableSignal} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DlcRoundedTextButtonComponent} from '../../../button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcImageCropperComponent} from '../image-cropper.component';
import {ImageCroppedEvent} from '../image-cropper/index';

export interface DialogData {
  imageFile: File | null;
}

@Component({
  selector: 'dlc-image-cropper-dialog',
  standalone: true,
  imports: [CommonModule, DlcImageCropperComponent, MatDialogModule, DlcRoundedTextButtonComponent],
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-image-cropper-dialog'
  }
})
export class ImageCropperDialogComponent {
  imageCroppedEvent: WritableSignal<ImageCroppedEvent | null> = signal(null);

  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close(this.imageCroppedEvent());
  }
}
