import {
  NG_PAT_ROLES,
  selectNgPatAddedAndDeletedEntities,
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {getMemberListItemsByProjectID, selectAllMemberss} from '../+members/index';
import {convertCollaborativeProjectToMemberUIDProjectID} from '../+members/member.fns';
import {UiState} from '../+ui/ui.model';
import {selectCurrentStudyGroupID, selectUiState} from '../+ui/ui.selectors';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {isCreatedBy} from '../fns/user.fns';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {StudyGroup} from './study-group.model';
import * as StudyGroupReducer from './study-group.reducer';
import {StudyGroupState} from './study-group.reducer';

export const selectStudyGroupState = createFeatureSelector<StudyGroupReducer.StudyGroupState>(
  StudyGroupReducer.studyGroupFeatureKey
);

const {selectIds, selectEntities, selectAll, selectTotal} = StudyGroupReducer.studyGroupAdapter.getSelectors();

export const selectAllStudyGroups = createSelector(selectStudyGroupState, selectAll);
export const selectStudyGroupEntities = createSelector(selectStudyGroupState, selectEntities);
export const selectStudyGroupIds = createSelector(selectStudyGroupState, selectIds);
export const selectStudyGroupTotal = createSelector(selectStudyGroupState, selectTotal);
export const selectedStudyGroupID = createSelector(
  selectStudyGroupState,
  (state: StudyGroupReducer.StudyGroupState) => state.selectedStudyGroupID
);

export const selectStudyGroupMemberMap = createSelector(
  selectAllStudyGroups,
  convertCollaborativeProjectToMemberUIDProjectID
);

export const selectedStudyGroup = createSelector(selectStudyGroupState, (state: StudyGroupState) => {
  if (state.selectedStudyGroupID) {
    return state.entities[state.selectedStudyGroupID];
  } else {
    return null;
  }
});

export const getStudyGroupByID = (studyGroupID: string | null) =>
  createSelector(selectStudyGroupState, (state: StudyGroupReducer.StudyGroupState): StudyGroup | undefined => {
    if (studyGroupID && state.entities[studyGroupID]) {
      return state.entities[studyGroupID];
    }

    return undefined;
  });

export const getStudyGroupByTimestamp = (nanoseconds: string | null | undefined) =>
  createSelector(selectAllStudyGroups, (studyGroups: StudyGroup[]) => {
    return studyGroups.reduce((found: StudyGroup | null | undefined, i: StudyGroup | undefined) => {
      if (!found && i && getCreatedAtNanoseconds(i) === nanoseconds) {
        return i;
      }

      return found;
    }, null);
  });

export const getStudyGroupById = (id: string | null | undefined) =>
  createSelector(selectAllStudyGroups, (studyGroups: StudyGroup[]) => {
    return studyGroups.reduce((found: StudyGroup | null | undefined, i: StudyGroup | undefined) => {
      if (!found && i && i.id === id) {
        return i;
      }

      return found;
    }, null);
  });

export const selectStudyGroupsIoOwn = createSelector(
  selectNgPatLoggedInUID,
  selectAllStudyGroups,
  (uid: string | null, studyGroups: StudyGroup[]) => {
    if (uid && uid.length) {
      return studyGroups.filter((s: StudyGroup) => (uid ? isCreatedBy(s, uid) : false));
    }

    return [];
  }
);

export const selectCurrentStudyGroup = createSelector(
  selectUiState,
  selectStudyGroupEntities,
  (state: UiState, studyGroupEntities: Dictionary<StudyGroup>): StudyGroup | null | undefined => {
    if (state.currentStudyGroupID && studyGroupEntities[state.currentStudyGroupID]) {
      return state.currentStudyGroupID ? studyGroupEntities[state.currentStudyGroupID] : null;
    }
    return null;
  }
);

export const selectCurrentStudyGroupMembers = createSelector(
  selectCurrentStudyGroupID,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  getMemberListItemsByProjectID
);

export const canInviteToStudyGroup = createSelector(
  selectCurrentStudyGroup,
  selectNgPatLoggedInUID,
  (studyGroup: StudyGroup | null | undefined, loggedInUID: string | null): boolean => {
    if (loggedInUID && studyGroup && studyGroup.members && studyGroup.members[loggedInUID]) {
      return studyGroup.members[loggedInUID].role === NG_PAT_ROLES.Owner;
    }
    return false;
  }
);

export const selectAddedAndDeletedStudyGroups =
  selectNgPatAddedAndDeletedEntities<StudyGroup>(selectStudyGroupEntities);
