import {ChangeDetectionStrategy, Component, input, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'dlc-mobile-page-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dlc-mobile-page-content.component.html',
  styleUrls: ['./dlc-mobile-page-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: `dlc-mobile-page-content`,
    '[class.dlc-mobile-page-content--height-full]': 'heightFull()',
    '[class.dlc-mobile-page-content--overflow-y-scroll]': 'overflowYScroll()',
    '[style.padding-left]': 'paddingLeft()',
    '[style.padding-right]': 'paddingLeft()'
  }
})
export class DlcMobilePageContentComponent {
  heightFull = input(false);
  overflowYScroll = input(true);
  paddingLeft = input('1.25rem');
  paddingRight = input('1.25rem');
}
