import {Injectable} from '@angular/core';
import {
  Exists,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {aggregateUpdates, NgPatAccountState, NgPatFirebaseConnectionService, NgPatServiceConnector} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {CollaborativeProject, Project} from '../+project/project.model';
import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {
  firestoreClassCollection,
  firestoreClassDoc,
  firestoreQueryPathByProject,
  firestoreQuizCollection,
  firestoreUserClassCollection
} from '../firebase/database-paths';
import {QueryEngineCache} from '../services/query-engine-cache';
import {deleteClassrooms, updateClassrooms, upsertClassrooms} from './classroom.actions';
import {Classroom} from './classroom.model';
import {classroomFeatureKey} from './classroom.reducer';
import {selectAllClassrooms} from './classroom.selectors';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService implements NgPatFirebaseConnectionService {
  private _queryClassrooms!: NgPatFirestoreCollectionQuery<Classroom>;
  private _queryPrivateClassroomsService!: NgPatFirestoreCollectionQuery<Classroom>;
  private _classroomQuizQueryCache!: QueryEngineCache<Quiz>;

  connectionKey = classroomFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(this, this.store);

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  ngPatOnInit() {
    this._queryClassrooms = new NgPatFirestoreCollectionQuery<Classroom>(
      {
        queryMember: true,
        upsertManyAction: (classrooms: Classroom[]) => upsertClassrooms({classrooms}),
        updateManyAction: (classrooms: Classroom[]) => updateClassrooms({classrooms: aggregateUpdates(classrooms)}),
        deleteManyAction: (ids: string[]) => deleteClassrooms({ids})
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateClassroomsService = new NgPatFirestoreCollectionQuery<Classroom>(
      {
        queryMember: false,
        upsertManyAction: (classrooms: Classroom[]) => upsertClassrooms({classrooms}),
        updateManyAction: (classrooms: Classroom[]) => updateClassrooms({classrooms: aggregateUpdates(classrooms)}),
        deleteManyAction: (ids: string[]) => deleteClassrooms({ids})
      },
      this.store,
      this.customFirestoreService
    );

    const queryClassroomQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        queryMember: false,
        upsertManyAction: (quizs: Quiz[]) => upsertQuizs({quizs}),
        updateManyAction: (quizs: Quiz[]) => updateQuizs({quizs: aggregateUpdates(quizs)}),
        deleteManyAction: (ids: string[]) => deleteQuizs({ids})
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (quiz: Quiz, uid: string) =>
      `${firestoreQueryPathByProject(quiz as Project, uid)}/${firestoreQuizCollection()}`;

    this._classroomQuizQueryCache = new QueryEngineCache<Quiz>(
      queryClassroomQuizConfig,
      this.store,
      selectAllClassrooms,
      quizPathGenerator,
      'id'
    );
  }

  deleteDoc$(classroom: Project | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate ? firestoreUserClassCollection(uid) : firestoreClassCollection();

      // const path = studyGroup.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: Project | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate ? firestoreUserClassCollection(uid) : firestoreClassCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }
    return of(true);
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    if (this._queryClassrooms) {
      this._queryClassrooms.onConnect(firestoreClassCollection(), null, <string>user.uid);
    }

    if (this._queryPrivateClassroomsService) {
      this._queryPrivateClassroomsService.onConnect(firestoreUserClassCollection(<string>user.uid));
    }

    if (this._classroomQuizQueryCache) {
      this._classroomQuizQueryCache.onConnect(user);
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    if (this._queryClassrooms) {
      this._queryClassrooms.onDisconnect();
    }

    if (this._queryPrivateClassroomsService) {
      this._queryPrivateClassroomsService.onDisconnect();
    }

    this._classroomQuizQueryCache.onDisconnect();
  }

  createClassroom(classroom: Classroom) {
    const fireStorePath = firestoreClassDoc(classroom.id);
    return this.customFirestoreService.upsertDoc$(fireStorePath, classroom);
  }

  updateDoc(g: CollaborativeProject | Classroom) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByProject(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(changes: Partial<Classroom>, quiz: Classroom, uid: string | null): Observable<Classroom> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Classroom>(firestoreQueryPathByProject(quiz, uid), changes)
        .pipe(map((r: Exists<Classroom>) => r.data));
    }

    return of(quiz);
  }
}
