import {CreateProjectParams, createProjectParamsFromParentProject, Project} from '../../+project';
import {BASE_COLLECTION_NAME} from './model';
import {firestoreProjectPathByParams, firestoreQueryPathByProject} from './projects';

export function firestoreQuizGradesByProject(p: Project, uid: string) {
  if (
    p.parentProjectID &&
    p.parentProjectID.length &&
    p.parentProjectType !== null &&
    p.parentProjectType !== undefined
  ) {
    const params: CreateProjectParams = createProjectParamsFromParentProject(p);
    return `${firestoreProjectPathByParams(params, uid, p.parentProjectID)}/${BASE_COLLECTION_NAME.QUIZ_RESULTS}`;
  } else {
    return `${firestoreQueryPathByProject(p, uid)}/${BASE_COLLECTION_NAME.QUIZ_RESULTS}`;
  }
}

export function firestoreQuizGradeByProject(p: Project, uid: string, resultID: string) {
  return `${firestoreQuizGradesByProject(p, uid)}/${resultID}`;
}
